import type { MainMenu, MenuQuery } from "@onestore-graphql/Model"
import { useStaticQuery, graphql } from "gatsby"

interface Query {
  GraphCmsSquidex: {
    menu: MainMenu[]
  }
}

export default function useMenuQuery(): MenuQuery {
  const {
    GraphCmsSquidex: { menu },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        menu: queryMenuContents {
          id
          flatData {
            name
            siteName
            gaLogoEvent {
              ...GaEvent
            }
            gaCartEvent {
              ...GaEvent
            }
            logo {
              id
              url
            }
            logoDarkMode {
              id
              url
              pixelWidth: metadata(path: "pixelWidth")
              pixelHeight: metadata(path: "pixelHeight")
            }
            promoLink {
              id
              flatData {
                title
                url
                gaEvent {
                  ...GaEvent
                }
                hiddenOnMobile
              }
            }
            links {
              id
              flatData {
                title
                url
                hiddenOnMobile
                gaEvent {
                  ...GaEvent
                }
              }
            }
            navigation {
              id
              flatData {
                name
                subtitle
                url
                urlTitle
                items {
                  id
                  flatData {
                    name
                    subtitle
                    url
                    items {
                      id
                      flatData {
                        name
                        subtitle
                        url
                        urlTitle
                        widget
                        label
                        gaEvent {
                          ...GaEvent
                        }
                      }
                    }
                    gaEvent {
                      ...GaEvent
                    }
                  }
                }
                secondaryItems {
                  id
                  flatData {
                    name
                    subtitle
                    url
                    urlTitle
                    widget
                    gaEvent {
                      ...GaEvent
                    }
                  }
                }
                gaEvent {
                  ...GaEvent
                }
              }
            }
          }
        }
      }
    }
  `)

  return {
    menu: menu[0],
  }
}
