import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import IconUnavailable from "@onestore-icons/domain-search/icon_unavailable.svg"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import DomainSearchResultBoxOrg from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg"
import type { AdditionalInfoProps } from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg/AdditionalInfo"
import type { SubItemProps } from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg/SubItem"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import ScrollToCategoriesButton from "@gatsby-plugin-domain-search/components/Button/ScrollToCategoriesButton"
import { getDomainSearchFqdn } from "@gatsby-plugin-domain-search/store/selectors"
import { md } from "~/lib/i18n"
import isEmpty from "~/lib/isEmpty"
import { TextAtmBoldError } from "~/lib/markdown"
import { useAppSelector } from "~/store/hooks"
import { COMPONENT_ID } from "~/types/component"

interface DomainTransferResultBoxPros {
  subItems: SubItemProps[] | undefined
  footer: ReactNode
  unavailableExtensions: string[]
  fqdnDomain?: string
  priceToggle?: ReactNode
  additionalInfo?: AdditionalInfoProps
}

export default function UnavailableExtensionsResultBox({
  subItems,
  footer,
  fqdnDomain,
  priceToggle,
  additionalInfo,
}: DomainTransferResultBoxPros): ReactElement<DomainTransferResultBoxPros> {
  const isSmallOnly = useBreakpoint("small", "medium")
  const fqdn = useAppSelector(getDomainSearchFqdn)

  return (
    <DomainSearchResultBoxOrg
      header={
        <LabelAtm
          size="large"
          variant="errorLight"
          emphasis="high"
          borderRadius="mlarge"
        >
          <TextAtm typography="small1">
            {md(
              "domainSearch.mainDomainHeaderTextExtensionsUnavailable",
              undefined,
              {
                strong: TextAtmBoldError,
              }
            )}
          </TextAtm>
        </LabelAtm>
      }
      image={<IconUnavailable />}
      main={
        <div id={COMPONENT_ID.DOMAIN_SEARCH_MAIN_RESULT}>
          <TextAtm
            typography="large2"
            align={isSmallOnly && !additionalInfo ? "center" : undefined}
            pushSpace={0.5}
          >
            {fqdnDomain || fqdn}
          </TextAtm>
          <TextAtm typography="tiny1">
            {_t(
              isEmpty(additionalInfo)
                ? "domainSearch.mainDomainInfoTextExtensionsUnavailable"
                : "domainSearch.premiumMainDomainExtensionsUnavailable"
            )}
          </TextAtm>
        </div>
      }
      aside={
        <ScrollToCategoriesButton
          isExpandedOnMobile={!!additionalInfo}
          isWider={!!additionalInfo}
        />
      }
      footer={footer}
      subItems={subItems}
      priceToggle={priceToggle}
      additionalInfo={additionalInfo}
    />
  )
}
