import { useState } from "react"
import { navigate } from "gatsby"
import useDomainSearchAiSettingsQuery from "@gatsby-plugin-domain-search/hooks/useDomainSearchAiSettingsQuery"
import useDomainSearchPromptState from "@gatsby-plugin-domain-search/hooks/useDomainSearchPromptState"

export default function useDomainSearchPromptInput() {
  const [isPending, setIsPending] = useState<boolean>(false)
  const { url } = useDomainSearchAiSettingsQuery()
  const { value, setValueToRandomPrompt, setValue } =
    useDomainSearchPromptState()

  const onSubmitButtonClick = () => {
    setIsPending(true)
    navigate(url, {
      state: {
        prompt: value,
      },
      replace: true,
    })
  }

  return {
    isPending,
    value,
    setValueToRandomPrompt,
    setValue,
    onSubmitButtonClick,
  }
}
