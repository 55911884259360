import type { ReactElement, ReactNode } from "react"
import { useContext } from "react"
import React from "react"
import _t from "@core/i18n"
import { PeriodInfo } from "@core/period-info"
import { hasNoTaxRate } from "@core/pricing"
import { PriceFormat, PriceType } from "@core/types"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import AdvancedProductTileMol from "@onestore/hel/dist/components/molecules/AdvancedProductTileMol"
import { TooltipWrapper } from "@onestore/hel/dist/components/molecules/AdvancedProductTileMol/AdvancedProductTileMol.styled"
import TooltipMol from "@onestore/hel/dist/components/molecules/TooltipMol"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import Picture from "@onestore/hel/dist/components/quarks/Picture"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import MarkdownTile from "@gatsby-plugin-generic-page/components/Markdown/Tile"
import type { Label } from "@gatsby-plugin-generic-page/fragments/label"
import LimitTooltipLabel from "~/components/LimitTooltipLabel"
import LowestPriceInfo from "~/components/LowestPriceInfo"
import PriceTypeContext from "~/context/PriceTypeContext"
import { useCloudblueDomainInfo } from "~/hooks/useCloudblueDomainInfo"
import { safeFind } from "~/lib/collection"
import { selectDomainEvent } from "~/lib/ga4"
import isEmpty from "~/lib/isEmpty"

export interface DomainProps {
  planId: number
  link?: string
  tooltip?: string
  extension?: string
  period?: string
  label?: Label
  title?: string
  suffix?: string
  description?: ReactNode
  logo?: string
  periods: CloudBluePeriod[]
  onClick?: { (): void }
}

export default function AdvancedDomainItem({
  planId,
  extension,
  period,
  title,
  suffix,
  label,
  description,
  link,
  logo,
  periods,
}: DomainProps): ReactElement<DomainProps> | null {
  const { tooltip, limitTooltip } = useCloudblueDomainInfo(planId)
  const priceTypeContext = useContext(PriceTypeContext)
  const priceType = hasNoTaxRate()
    ? PriceType.NETTO
    : priceTypeContext?.currentPriceType

  const periodInfo = new PeriodInfo(
    periods.find((item) => item.period_name === period) ||
      safeFind(periods, (item) => item.default ?? false)
  )

  const hasPromotion = periodInfo.hasPromotion()
  const hasLimitTooltip = periodInfo.hasLimit()

  const { priceValue, lowestPrice, lowestPricePercent } =
    periodInfo.usePrices(priceType)

  function SendSelectDomainEvent() {
    selectDomainEvent(
      periods,
      periodInfo.getFormatted(PriceFormat.REGISTER_NETTO_NO_CURRENCY_NO_PERIOD),
      title
    )
  }

  const priceFormatSuffix = `${_t(`prices.${priceType}`)} ${_t(
    `periodName.searchDomainPrice.3`,
    {
      smart_count: periodInfo?.getPeriodLength(),
    }
  )}`

  return (
    <AdvancedProductTileMol
      label={
        hasPromotion || hasLimitTooltip ? (
          <FlexContainerOrg gapSpace={1} wrap="wrap">
            {hasPromotion ? (
              <LabelAtm size="small" variant="notifyLight" emphasis="high">
                {_t("marketplace.discountTitle")}
              </LabelAtm>
            ) : !isEmpty(label) ? (
              <LabelAtm size="small" variant={label?.variant}>
                {label?.text}
              </LabelAtm>
            ) : null}

            {hasLimitTooltip && limitTooltip ? (
              <LimitTooltipLabel tooltipText={limitTooltip} />
            ) : null}
          </FlexContainerOrg>
        ) : undefined
      }
      logo={
        <Picture
          srcData={logo ? logo : ""}
          alt={`${_t("domains.domain")} .${extension}`}
        />
      }
      title={
        <TextAtm typography="large3">{title ? title : `.${extension}`}</TextAtm>
      }
      description={
        <TextAtm typography="xsmall1" emphasis="medium">
          {description}
        </TextAtm>
      }
      price={
        <>
          <WrapOrg gutterSpace={0.5} alignCenter="vertical">
            <FlexContainerOrg alignItems="baseline">
              <TextAtm
                typography="big3"
                color={hasPromotion ? "primary" : undefined}
              >
                {priceValue}
              </TextAtm>

              <PushOrg leftSpace={0.5}>
                <TextAtm typography="tiny2" emphasis="medium">
                  {suffix || priceFormatSuffix}
                </TextAtm>
              </PushOrg>
            </FlexContainerOrg>

            {tooltip ? (
              <TooltipWrapper>
                <TooltipMol iconSize="large">
                  <MarkdownTile>{tooltip}</MarkdownTile>
                </TooltipMol>
              </TooltipWrapper>
            ) : undefined}
          </WrapOrg>

          {hasPromotion && lowestPrice ? (
            <PushOrg topSpace={2}>
              <LowestPriceInfo
                value={lowestPrice}
                percent={lowestPricePercent}
              />
            </PushOrg>
          ) : null}
        </>
      }
      link={{
        title: `${_t("domains.domain")} .${extension}`,
        to: link,
      }}
      data-test-id="domain-ext-tile"
      onClick={SendSelectDomainEvent}
    />
  )
}
