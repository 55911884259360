import type { OptionMapping } from "@gatsby-plugin-marketplace/fragments/optionMapping"
import type { SaleConfigurationState } from "@gatsby-plugin-marketplace/store/selectors"
import { getSaleConfiguration } from "@gatsby-plugin-marketplace/store/selectors"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"
import { useAppSelector } from "~/store/hooks"
import { getSelectedPlanSettings } from "~/utils"

export default function useCurrentPlanSettings(
  saleConfiguration?: SaleConfiguration
): OptionMapping | null {
  const uuid = saleConfiguration?.id

  const saleConfigurationState = useAppSelector<SaleConfigurationState | null>(
    (state) => (uuid ? getSaleConfiguration(state, uuid) : null)
  )

  if (isEmpty(saleConfigurationState)) {
    return null
  }

  return getSelectedPlanSettings(
    saleConfiguration?.flatData.optionsMapping || [],
    saleConfigurationState.options
  )
}
