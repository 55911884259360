import type {
  PriceTypeValues,
  PriceWithOptionalTaxRate as PriceValue,
} from "@core/types"
import type { Account } from "./account"
import type { BasketResponse, ProductType } from "./basket"
import type { DomainCheck } from "./domainSearch"

export type TaxRate = number // 23
export type TaxValue = number // 0.23
export type CurrencySign = string // zł, €
export type CurrencyCode = string // PLN, EUR
export type PeriodName = "0d" | "2m" | "1m" | "1y" | string
export type PeriodType = number
export type PeriodValue = number

export type ProductAlias = string
export type PlanId = string | number
export type PlanPeriodId = string | number
export type PlanPeriodNumericId = number
export type ResourceId = number

export interface SessionResponse {
  id: string
  acccount_id: number
  user_id: number
  expire_at: string
  created_at: string
}

export interface Price {
  netto: number
  gross: number
  taxRate: TaxRate
  currency: string
}

export interface Query {
  [key: string]: string | null
}

export interface JSONResponse {}
export type NoContentResponse = never

export interface BasketPatchResource {
  id?: number
  resource?: number
  quantity: number
}

export interface BasketPatchResourceWithResourceId {
  resource: number
  quantity: number
}

export interface BasketPatchItem {
  plan: number
  planPeriod?: number
  parameters?: Record<string, string | number | boolean | null>
  resources?: BasketPatchResource[]
  children?: BasketPatchItem[]
  ignoreHack?: boolean
  parent?: number
  quantity: number
}

export interface CalculateBasketPayload {
  items: BasketPatchItem[]
  promo_code?: string | null
}

export interface ProductPriceResponse {
  regularPrice: PriceValue
  promoPrice: PriceValue | null
  renewalPrice: PriceValue | null
  discount: number | null
  periodValue: PeriodValue | null
  periodType: PeriodType | null
  periodName: PeriodName | null
  lowestPurchasePrice: PriceValue | null
}

export interface UserDataResponse {
  basket: BasketResponse
  user?: Account
}

export type AccountType = "person" | "company"

export type NullableAccountType = AccountType | null

// Api Types

export interface JSONPatch {
  op: "add" | "remove" | "replace"
  path?: string
  value?: any
}

export interface Parameter {}

export interface Resource {
  id: number
  setup_fee_changed_per_unit: boolean
  alias: string
  name: string
  description: string
  measure_unit: string
  /**
   * @deprecated Nie używać, zostaje usunięte w API 24.04.8 (ONESTORE-123)
   */
  min_value?: number | never
  /**
   * @deprecated Nie używać, zostaje usunięte w API 24.04.8 (ONESTORE-123)
   */
  max_value?: number | never
  /**
   * @deprecated Nie używać, zostaje usunięte w API 24.04.8 (ONESTORE-123)
   */
  included_value?: number | never
  /**
   * @deprecated Nie używać, zostaje usunięte w API 24.04.8 (ONESTORE-123)
   */
  product_type?: string | never
}

export type DomainExtension = string

export interface DomainTransferDefinition {
  extension: DomainExtension
  id: number
  price_cession: null | number
  price_recover: null | number
  price_transfer: null | number
  authinfo_required: boolean
}

export type CountryCode = string // PLN, EUR

export interface CheckUserPasswordResponse {
  signature: string
  session?: SessionResponse
}

export interface AccountMessage {
  id: string
  message: string
  type: "info" | string
  class?: "CITY_TYPO" | string
}

export interface TaxNumberData {
  type: "vat" | "regon"
  id: string
  eu_id?: string
  country_code: string
}

export interface PhoneNumberData {
  country_code: string
  number: string
}

export interface OrderResponse {
  redirect_data: {
    url: string
    method: "MODAL" | "POST" | "GET"
    data: null
  }
  bank_transfer_data: {
    title: string
    accountNumber: string
    recipient: string[]
  }
  token: string
  modal_form: {
    checkoutScriptSrc: string
    checkoutScriptSection: string
    checkoutScriptHandler: string
    checkoutScriptHtml: string
  }
  id: number
  number: string
  pay_tool_type: number
  total_value: number
  total_vat_value: number
  control_panel_url: string
  product_types: ProductType[]
}

export interface CheckoutResponse {
  session?: SessionResponse
  order: OrderResponse
}

export enum TermType {
  EXTERNAL = "external",
  INTERNAL = "internal",
}

export interface Term {
  id: number
  name: string
  optional: boolean
  text: string
  type: TermType
  checked: boolean
}

export type TermResponse = Term[]

export interface DomainBundlePricing {
  register_price: number
  register_price_gross: number
  regular_price: number
  regular_price_gross: number
  renewal_price: number
  renewal_price_gross: number
  savings: number
  period_type: PeriodType
  period_value: PeriodValue
}

export interface CheckMainDomainResponse {
  alternative: DomainCheck.Result | null
  premium: DomainCheck.Result | null
  special: DomainCheck.Result | null
  bundles: DomainCheck.DomainSearchBundle[]
  domain: DomainCheck.Result
  unavailable_extensions: DomainCheck.Extension[]
  phrase: DomainCheck.Phrase
}

export type CheckDomainsResponse = DomainCheck.Result[]
export type CheckSuggestionsResponse = DomainCheck.Result[]

export interface InvoiceOrderApiFormError {
  errors?: string[]
}

export interface InvoiceOrderApiResponse {
  status: number
  errors: string[]
  form: {
    children: {
      accountId: InvoiceOrderApiFormError
      email: InvoiceOrderApiFormError
      from: InvoiceOrderApiFormError
      to: InvoiceOrderApiFormError
      type: InvoiceOrderApiFormError
      terms: InvoiceOrderApiFormError
    }
  }
}

type PmailDomain = string
type PmailStatus = "Available"

export interface CheckPmailResponseItem {
  plan_id: PlanId
  alias: string
  priority: number
  is_promoted: boolean
  name: string
  domain: PmailDomain
  status: PmailStatus
}

export interface CheckPmailResponse {
  results: CheckPmailResponseItem[]
  phrase: string
  phraseSanitized: string
}

export interface CheckOfficeDomainOwnershipResponse {
  status: "accepted" | "error" | "pending"
  errors: string[]
  username: string
}
export interface CheckOfficeDomainResponse {
  available: boolean
}

export interface JSONData {}

export interface PaymentModalData {
  checkoutScriptSection: string
  checkoutScriptHtml: string
  checkoutScriptHandler: string | null | undefined
  checkoutScriptSrc: string | null | undefined
}

export interface ProductPriceDefinition {
  regularPrice: PriceTypeValues
  promoPrice: PriceTypeValues | null
  renewalPrice: PriceTypeValues | null
  discount: number | null
  periodValue: PeriodValue | null
  periodType: PeriodType | null
  periodName: PeriodName | null
}

export interface FetchCalculateProductsPayload {
  [key: string]: {
    items: BasketPatchItem[]
  }
}

export interface FetchCalculateProductsResponse {
  [key: string]: ProductPriceResponse
}
