import { get, pick } from "lodash"
import type { ThunkAction } from "redux-thunk"
import type {
  BasketResponse,
  Basket,
  BasketResponseItem,
  PaymentMethodWithLogo,
} from "@onestore/api/basket"
import type {
  BasketPatchItem,
  PlanId,
  UserDataResponse,
} from "@onestore/api/types"
import type {
  BasketStateItem,
  BasketUpdateItem,
  HTTP,
} from "@onestore/onestore-store-common"
import { ItemRemoval } from "@onestore/onestore-store-common"
import {
  addBasketItem,
  addBasketItemChild,
  changePromoCode,
  changePaymentMethod as changePaymentMethodOperation,
} from "@onestore/onestore-store-common/api/operations"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type { BonusProductActionsType } from "@gatsby-plugin-bonus/store/actions-product"
import type { BonusUpsellActionType } from "@gatsby-plugin-bonus/store/actions-upsell"
import { getProduct } from "@gatsby-plugin-bonus/store/selectors"
import type { BonusProduct, BonusResource } from "@gatsby-plugin-bonus/types"
import {
  getBasketParams,
  getBasketTerms,
} from "@gatsby-plugin-checkout/store/actions"
import { CheckoutActions } from "@gatsby-plugin-checkout/store/constants"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import StoreAPI from "~/lib/api"
import { forceRedirectToIndex } from "~/lib/browser"
import { getHostingHookPlanId, getSecondaryHookPlanId } from "~/lib/config"
import { EmptyTokenException } from "~/lib/exceptions"
import { createAccountUpdateAction } from "~/store/account/actions"
import { addRequestErrorGlobalMessage } from "~/store/messages/actions"
import type { AppState, AppDispatch } from "~/store/reducer"
import { BASKET_STATUS, BasketActions } from "./constants"
import {
  flattenItems,
  getFlatBasketViewItemsWithLinkedHook,
  getRemovedBasketItem,
  isBasketLoading,
} from "./selectors"
import { getBasketItemValues, getLastItemsFromBasket } from "./utils"

export enum BasketActionSource {
  EMPTY = "",
  BASKET = "basket",
  SUMMARY = "summary",
  BONUS = "bonus",
  TRANSFER = "transfer",
  PARAMETERS = "parameters",
  DOMAINS = "domains",
  GENERIC_PAGE = "generic-page",
  PMAILS = "pmails",
  MODAL = "modal",
}
export interface CheckoutUserDataPendingAction {
  type: CheckoutActions.USER_DATA_PENDING
}

export interface CheckoutUserDataSuccessAction {
  type: CheckoutActions.USER_DATA_SUCCESS
  response_status_code: HTTP.Status
  result: UserDataResponse
}

export interface CheckoutUserDataFailureAction {
  type: CheckoutActions.USER_DATA_FAILURE
}

export interface BasketGetAction {
  type: "BASKET.ACTIONS.BASKET"
}

export interface BasketResetButtonAction {
  type: "BASKET.ACTIONS.BASKET_RESET_BUTTON"
  buttonId: string
}

export interface BasketPromoCodeErrorAction {
  type: "BASKET_PROMO_CODE_ERROR"
  message: string
}

export interface BonusUpsellDomainsAddSuccessAction {
  type: "@bonus/BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_SUCCESS"
  buttonId: string
  status: HTTP.Status
}

export interface BonusUpsellDomainsAddFailureAction {
  type: "@bonus/BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_FAILURE"
  buttonId: string
  status: HTTP.Status
}

export interface BonusUpsellDomainsAddRequestAction {
  type: "@bonus/BONUS_UPSELL_DOMAINS_ADD_TO_BASKET_REQUEST"
  buttonId: string
  status: HTTP.Status
}

export interface BasketResponseWithId extends BasketResponse {
  id: number
  number: string
}

export interface BasketResponseAction {
  type:
    | BasketActions.BASKET_CHANGE_PAYMENT
    | BasketActions.BASKET_ITEM_REMOVED
    | CheckoutActions.PAYMENT_SUCCESS
    | BasketActions.BASKET_SUCCESS
    | BonusProductActionsType.BONUS_CHANGE_PERIOD_CLICK
    | BonusUpsellActionType.BONUS_UPSELL_CHANGE_PERIOD_CLICK
  result: BasketResponseWithId
  period: CloudBluePeriod
  upsell: BonusProduct
  basket: any
  basketOrderedItems: BasketStateItem[]
  value: number
  payment_type: number
  system_id: string
  paymentMethod: number
  paymentMethods: {
    description: string
    id: number
    selected: boolean
    system_id: string
    use_for_auto_payments: boolean
    variant: string
  }[]
  cart_id: string
  id: number
  item_id: string
  name: string
  price: number
  quantity: number | undefined
  affiliation: string | undefined
  item_category: string | undefined
  item_variant: string | undefined
}

export interface BasketAddAction {
  type: "BASKET.ACTIONS.BASKET"
  buttonId?: string
  source: BasketActionSource.DOMAINS
  basketItemsIds?: number[]
}

export interface BasketSuccessAction {
  type: "BASKET.ACTIONS.BASKET_SUCCESS"
  buttonId?: string
  result: BasketResponse
  source?: "domains"
  basketItemsIds?: number[]
  removedItems?: BasketStateItem[]
}

export interface BasketChangePaymentAction {
  type: "BASKET.ACTIONS.BASKET_CHANGE_PAYMENT"
  result: BasketResponse
  source?: "summary"
  basketItemsIds?: number[]
}

export interface BasketDomainHookAddAction {
  type: BasketActions.MANUAL_DOMAIN_HOOK_ADD
  plan: PlanId
}

export interface BasketErrorAction {
  type: "BASKET.ACTIONS.BASKET_ERROR"
  buttonId?: string
  message: string
  response_status_code: HTTP.Status
  basketItemsIds?: number[]
}

export interface BasketItemRemovedAction {
  type: "BASKET.ACTIONS.BASKET_ITEM_REMOVED"
  id: number
  item_id: string
  name: string
  price: number
  quantity: number
  resources: Record<number, BonusResource>
  index: number | null
  affiliation: string
  item_category: string
  item_variant: string
}

export interface BasketRemoveConfirmationShowAction {
  type: BasketActions.REMOVE_ITEM_CONFIRM_MODAL_SHOW
  itemId: number
  planId: PlanId | undefined
  actionSource: BasketActionSource
  itemRemoval: ItemRemoval
}

export interface BasketRemoveConfirmationCloseAction {
  type: BasketActions.REMOVE_ITEM_CONFIRM_MODAL_CLOSE
}
export interface BasketRemoveConfirmationConfirmAction {
  type: BasketActions.REMOVE_ITEM_WITH_CONFIRMATION
}

export type BasketAction =
  | BasketAddAction
  | BasketDomainHookAddAction
  | BasketSuccessAction
  | BasketErrorAction
  | CheckoutUserDataPendingAction
  | CheckoutUserDataSuccessAction
  | CheckoutUserDataFailureAction
  | BasketGetAction
  | BasketPromoCodeErrorAction
  | BasketResetButtonAction
  | BonusUpsellDomainsAddSuccessAction
  | BonusUpsellDomainsAddFailureAction
  | BonusUpsellDomainsAddRequestAction
  | BasketItemRemovedAction
  | BasketRemoveConfirmationShowAction
  | BasketRemoveConfirmationCloseAction

export type BasketThunkAction = ThunkAction<
  void,
  AppState,
  undefined,
  BasketAction
>

export const resetButtonStatus = (
  buttonId: string
): BasketResetButtonAction => {
  return {
    type: "BASKET.ACTIONS.BASKET_RESET_BUTTON",
    buttonId,
  }
}

export const showRemoveConfirmationModal = (
  basketItemId: number,
  actionSource: BasketActionSource,
  itemRemoval: ItemRemoval,
  planId: PlanId | undefined
): BasketRemoveConfirmationShowAction => ({
  type: BasketActions.REMOVE_ITEM_CONFIRM_MODAL_SHOW,
  itemId: basketItemId,
  planId,
  actionSource,
  itemRemoval,
})

export const closeRemoveConfirmationModal =
  (): BasketRemoveConfirmationCloseAction => ({
    type: BasketActions.REMOVE_ITEM_CONFIRM_MODAL_CLOSE,
  })
export const removeItemFromBasketWithConfirmation = () => {
  return async (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    const { itemId, planId, actionSource } = getRemovedBasketItem(getState())

    if (itemId) {
      dispatch(
        removeItemFromBasket(
          itemId,
          planId,
          null,
          actionSource,
          ItemRemoval.ALLOW
        )
      )
    } else {
      dispatch(closeRemoveConfirmationModal())
    }
  }
}

export function handleBasketResponse(
  dispatch: AppDispatch<BasketThunkAction>,
  promise: Promise<BasketResponse>,
  basketItemsIds: number[] = []
) {
  return promise
    .catch((e) => {
      dispatch({
        type: BasketActions.BASKET_ERROR,
        response_status_code:
          get(e, ["error", "code"]) || HTTP_STATUS.INTERNAL_SERVER_ERROR,
        message: e.message || "",
        basketItemsIds,
      } as BasketErrorAction)
    })
    .then((result) => {
      if (result === undefined) {
        return Promise.reject()
      }

      dispatch({
        type: BasketActions.BASKET_SUCCESS,
        result,
        basketItemsIds,
      } as BasketSuccessAction)

      return Promise.resolve(result)
    })
}

const checkTermsRefresh = (
  source: BasketActionSource,
  dispatch: AppDispatch<BasketThunkAction>,
  result: BasketResponse
) => {
  if (
    source === BasketActionSource.SUMMARY &&
    [BASKET_STATUS.ASSIGNED, BASKET_STATUS.HAS_USER_DATA].indexOf(
      result.status
    ) !== -1
  ) {
    dispatch(getBasketTerms())
  }
}

const checkParametersRefresh = (
  source: BasketActionSource,
  dispatch: AppDispatch<BasketThunkAction>
) => {
  if (source === BasketActionSource.PARAMETERS) {
    dispatch(getBasketParams())
  }
}

export const loadUserDataForToken = (
  token: Basket.Token
): BasketThunkAction => {
  return (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: CheckoutActions.USER_DATA_PENDING,
    } as CheckoutUserDataPendingAction)

    return StoreAPI.loadUserData(token)
      .catch((e: any) => {
        dispatch({
          type: CheckoutActions.USER_DATA_FAILURE,
          response_status_code: get(e, ["error", "code"]),
          message: e.message,
        })
      })
      .then((result: UserDataResponse | undefined): void => {
        if (!result) {
          return
        }

        dispatch({
          type: CheckoutActions.USER_DATA_SUCCESS,
          response_status_code: HTTP_STATUS.OK,
          result,
        } as CheckoutUserDataSuccessAction)

        if (result.user) {
          dispatch(createAccountUpdateAction(result.user))
        }
      })
  }
}

export const getBasket = (
  token: Basket.Token,
  refreshParams: boolean = false,
  actionSource: BasketActionSource = BasketActionSource.EMPTY
): BasketThunkAction => {
  return async (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    if (isBasketLoading(getState())) {
      return
    }

    dispatch({
      type: BasketActions.BASKET,
    })

    try {
      const result = await StoreAPI.basketGet(
        token,
        actionSource === BasketActionSource.BASKET
      ).catch((e) => {
        dispatch({
          type: BasketActions.BASKET_ERROR,
          response_status_code: get(e, ["error", "code"]),
          message: e.message,
        })
      })

      if (undefined === result) {
        return
      }

      if (refreshParams) {
        await dispatch(getBasketParams())
      }

      dispatch({
        type: BasketActions.BASKET_SUCCESS,
        result,
      } as BasketSuccessAction)
    } catch (error) {
      if (error instanceof EmptyTokenException) {
        forceRedirectToIndex()

        return
      }

      throw error
    }
  }
}

export const addItemsToBasket = (
  basketItems: BasketPatchItem[],
  buttonId: string | null,
  source: BasketActionSource,
  onSuccess: { (result: BasketResponse): void } = () => {},
  promoCode: string | null = null
) => {
  return (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BasketActions.BASKET,
      buttonId,
      basketItemsIds: [buttonId],
      source,
    })
    const state = getState()
    const operations = basketItems.map((item) => {
      if (item.parent) {
        return addBasketItemChild(item.parent, item)
      }

      return addBasketItem(item)
    })

    if (promoCode) {
      operations.push(changePromoCode(promoCode))
    }

    const hostingHookAdded = basketItems.some(
      (item) => item.plan === getHostingHookPlanId()
    )

    const secondaryHookAdded = basketItems.some(
      (item) => item.plan === getSecondaryHookPlanId()
    )

    return StoreAPI.basketPatch(
      state.basket.token,
      operations,
      source === BasketActionSource.BASKET
    )
      .catch((e) => {
        let errorCode
        let message

        if (e.form) {
          errorCode = HTTP_STATUS.BAD_REQUEST
        } else {
          errorCode =
            get(e, ["error", "code"]) || HTTP_STATUS.INTERNAL_SERVER_ERROR
        }

        message = e.message || ""

        if (source === BasketActionSource.TRANSFER) {
          const messages = get(e, [
            "form",
            "children",
            "parameters",
            "children",
            "authinfo",
            "errors",
          ])

          message = messages.pop()
        }

        dispatch({
          type: BasketActions.BASKET_ERROR,
          buttonId,
          response_status_code: errorCode,
          message,
          source,
        })

        return Promise.reject(e)
      })
      .then((result: BasketResponse) => {
        if (result === undefined) {
          return
        }

        if (hostingHookAdded) {
          dispatch({
            type: BasketActions.MANUAL_DOMAIN_HOOK_ADD,
            plan: getHostingHookPlanId(),
          } as BasketDomainHookAddAction)
        }

        if (secondaryHookAdded) {
          dispatch({
            type: BasketActions.MANUAL_DOMAIN_HOOK_ADD,
            plan: getSecondaryHookPlanId(),
          } as BasketDomainHookAddAction)
        }

        dispatch({
          type: BasketActions.BASKET_SUCCESS,
          buttonId,
          result,
          source,
          basketOrderedItems: getFlatBasketViewItemsWithLinkedHook(state),
        } as BasketSuccessAction)
        checkTermsRefresh(source, dispatch, result)
        onSuccess(result)

        return Promise.resolve(getLastItemsFromBasket(result))
      })
  }
}

export const changePaymentMethod = (
  method: PaymentMethodWithLogo,
  source: BasketActionSource = BasketActionSource.SUMMARY
) => {
  return (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BasketActions.BASKET,
      source,
    })
    const state = getState()

    const basketOrderedItems = getFlatBasketViewItemsWithLinkedHook(state)

    dispatch({
      type: BasketActions.BASKET_CHANGE_PAYMENT,
      source,
      payment_type: method.id,
      system_id: method.system_id,
      items: state.basket.items,
      value: state.basket.totalValue,
      cart_id: state.basket.token,
      basketOrderedItems,
    })

    return StoreAPI.basketPatch(state.basket.token, [
      changePaymentMethodOperation(method.id),
    ])
      .catch((e) => {
        dispatch({
          type: BasketActions.BASKET_ERROR,
          message: e.message,
          response_status_code: get(e, ["error", "code"]),
          source,
        })

        return Promise.reject(e)
      })
      .then((result) => {
        if (undefined === result) {
          return
        }

        dispatch({
          type: BasketActions.BASKET_SUCCESS,
          result,
          source,
        } as BasketSuccessAction)

        checkTermsRefresh(source, dispatch, result)
        checkParametersRefresh(source, dispatch)
      })
  }
}

function findRemovedItems(
  result: BasketResponse,
  state: AppState
): BasketStateItem[] {
  const currentItems = flattenItems<BasketResponseItem>(result.items).map(
    (item) => item.id
  )

  return flattenItems<BasketStateItem>(state.basket.items).filter(
    (item) => !currentItems.includes(item.id)
  )
}

export const removeItemFromBasket = (
  basketItemId: number,
  planId: PlanId | undefined,
  buttonId: string | null = null,
  source: BasketActionSource = BasketActionSource.EMPTY,
  itemRemoval: ItemRemoval = ItemRemoval.ALLOW
) => {
  if (
    [
      ItemRemoval.CONFIRM_HACK_DOMAIN,
      ItemRemoval.CONFIRM_HACK_ITEM,
      ItemRemoval.CONFIRM_BUNDLE_ITEM,
    ].includes(itemRemoval)
  ) {
    return (dispatch: AppDispatch<BasketThunkAction>) => {
      dispatch(
        showRemoveConfirmationModal(basketItemId, source, itemRemoval, planId)
      )
    }
  }

  return (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BasketActions.BASKET,
      buttonId,
      basketItemsIds: [basketItemId],
      source,
    } as BasketGetAction)
    const state = getState()

    const basketOrderedItems = getFlatBasketViewItemsWithLinkedHook(state)

    return StoreAPI.basketPatch(
      state.basket.token,
      [{ op: "remove", path: `/items/${basketItemId}` }],
      source === BasketActionSource.BASKET
    )
      .catch((e) => {
        dispatch({
          type: BasketActions.BASKET_ERROR,
          buttonId,
          message: e.message,
          response_status_code: get(e, ["error", "code"]),
          source,
        } as BasketErrorAction)

        return Promise.reject(e)
      })
      .then((result: BasketResponse) => {
        if (undefined === result) {
          return
        }

        dispatch({
          type: BasketActions.BASKET_SUCCESS,
          buttonId,
          basketItemsIds: [basketItemId],
          result,
          source,
          removedItems: findRemovedItems(result, state),
        } as BasketSuccessAction)

        const {
          item_id,
          name,
          price,
          quantity,
          affiliation,
          item_category,
          item_variant,
        } = getBasketItemValues(basketItemId, state.basket.items)

        const product = getProduct(state)

        const resources: Record<number, BonusResource> = {}

        if (product) {
          Object.keys(product.resources).forEach((key) => {
            const resource = product.resources[key]

            if (resource.basketItemId) {
              resources[resource.id] = {
                ...resource,
                basketItemId: undefined,
              }
            }
          })
        }

        dispatch({
          type: BasketActions.BASKET_ITEM_REMOVED,
          id: basketItemId,
          item_id,
          name,
          price,
          quantity,
          resources,
          index: null,
          affiliation,
          item_category,
          item_variant,
          cart_id: state.basket.token,
          basketOrderedItems,
        } as BasketItemRemovedAction)

        checkTermsRefresh(source, dispatch, result)
        checkParametersRefresh(source, dispatch)
      })
  }
}

export const removeResourceItemFromBasket = (
  basketItemId: number,
  resourceItemId: number,
  buttonId: string | null = null,
  source: BasketActionSource = BasketActionSource.EMPTY
) => {
  return (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BasketActions.BASKET,
      buttonId,
      basketItemsIds: [resourceItemId],
      source,
    })
    const state = getState()

    return StoreAPI.basketPatch(
      state.basket.token,
      [
        {
          op: "remove",
          path: `/items/${basketItemId}/resources/${resourceItemId}`,
        },
      ],
      source === BasketActionSource.BASKET
    )
      .catch((e) => {
        dispatch({
          type: BasketActions.BASKET_ERROR,
          buttonId,
          message: e.message,
          response_status_code: get(e, ["error", "code"]),
          source,
        })
      })
      .then((result) => {
        if (undefined === result) {
          return
        }

        dispatch({
          type: BasketActions.BASKET_SUCCESS,
          buttonId,
          basketItemsIds: [resourceItemId],
          result,
          source,
        } as BasketSuccessAction)
        checkTermsRefresh(source, dispatch, result)
        checkParametersRefresh(source, dispatch)
      })
  }
}

export const updateItemsInBasket = (
  basketItems: BasketUpdateItem[],
  source: BasketActionSource,
  always = () => {}
) => {
  return (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BasketActions.BASKET,
      basketItemsIds: basketItems.map((item) => item.id),
      source,
    })
    const state = getState()

    return StoreAPI.basketPatch(
      state.basket.token,
      basketItems.map((item) => ({
        op: "replace",
        path: `/items/${item.id}`,
        value: pick(item, [
          "plan",
          "planPeriod",
          "parameters",
          "children",
          "quantity",
        ]),
      })),
      source === BasketActionSource.BASKET
    )
      .catch((e) => {
        dispatch({
          type: BasketActions.BASKET_ERROR,
          response_status_code: get(e, ["error", "code"]),
          message: e.message,
          source,
          basketItemsIds: basketItems.map((item) => item.id),
        })
        always()

        if (
          source === BasketActionSource.BASKET &&
          HTTP_STATUS.GONE === get(e, ["error", "code"])
        ) {
          dispatch(addRequestErrorGlobalMessage(e.error.message, true))
        }

        return Promise.reject()
      })
      .then((result) => {
        if (undefined === result) {
          Promise.resolve([])
        }
        dispatch({
          type: BasketActions.BASKET_SUCCESS,
          basketItemsIds: basketItems.map((item) => item.id),
          result,
          source,
        } as BasketSuccessAction)
        checkTermsRefresh(source, dispatch, result)
        always()

        return Promise.resolve(getLastItemsFromBasket(result))
      })
  }
}

export const updateBasketPromoCode = (
  promoCode: string,
  source: BasketActionSource
) => {
  return (
    dispatch: AppDispatch<BasketThunkAction>,
    getState: { (): AppState }
  ) => {
    dispatch({
      type: BasketActions.BASKET,
    })
    const state = getState()

    return StoreAPI.basketPatch(
      state.basket.token,
      [
        {
          op: "replace",
          path: "/promo_code",
          value: promoCode,
        },
      ],
      source === BasketActionSource.BASKET
    )
      .catch((e) => {
        dispatch({
          type: BasketActions.BASKET_PROMO_CODE_ERROR,
          message: e.message,
        })
      })
      .then((result) => {
        if (undefined === result) {
          return
        }
        dispatch({
          type: BasketActions.BASKET_SUCCESS,
          result,
        } as BasketSuccessAction)
      })
  }
}
