import { useStaticQuery, graphql } from "gatsby"
import type { DomainBundle } from "@gatsby-plugin-domain-search/fragments/domainBundle"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import isEmpty from "~/lib/isEmpty"

type Query = {
  GraphCmsSquidex: {
    findDomainSearchPageSingleton: {
      id: string
      flatData: {
        domainMainBundles?: DomainBundle[]
      }
    }
  }
}

export type DomainBundleHook = {
  title: string | null
  extensions: string[]
  id: string | number
  additionalBoxText?: string
  additionalBoxIcon?: EmbeddedAssetSquidexMetadata[]
}

export default function useMainDomainBundleQuery(): DomainBundleHook[] {
  const {
    GraphCmsSquidex: { findDomainSearchPageSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findDomainSearchPageSingleton {
          id
          flatData {
            domainMainBundles {
              ...DomainBundle
            }
          }
        }
      }
    }
  `)

  if (
    isEmpty(findDomainSearchPageSingleton) ||
    !findDomainSearchPageSingleton.flatData.domainMainBundles
  ) {
    return []
  }

  return findDomainSearchPageSingleton.flatData.domainMainBundles.map(
    (bundle, index) => ({
      id: index, // Squidex components don't have their own id, so we use the array index as the id
      title: bundle.text,
      extensions: bundle.extensions.map(
        (item): string => item.flatData.extension
      ),
      additionalBoxText: bundle.additionalBoxText,
      additionalBoxIcon: bundle.additionalBoxIcon,
    })
  )
}
