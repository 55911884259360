import type { ReactElement } from "react"
import React from "react"
import { typeMatches } from "@onestore-graphql"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import type {
  Color,
  SpacingSpace,
} from "@onestore/hel/dist/components/organisms/SurfaceOrg/SurfaceOrg.types"
import ThankYouMessage from "@gatsby-plugin-generic-page/components/ThankYouMessage"
import type { Formily as FormilyType } from "@gatsby-plugin-generic-page/fragments/formily"
import type { FormilyWithThankYou } from "@gatsby-plugin-generic-page/fragments/formilyWithThankYou"
import type { ThankYouMessageElement } from "@gatsby-plugin-generic-page/fragments/thankYouMessage"
import { SquidexFormType } from "@gatsby-plugin-generic-page/types/formType"
import Form from "~/components/Form"
import SalesManagoForm from "~/components/SalesManagoForm"
import type { Form as FormType } from "~/fragments/form"
import { getAdditionalDataFromSquidex } from "~/lib/forms"
import isEmpty from "~/lib/isEmpty"
import type { FormSalesManago, Forms as FormsType } from "~/types/squidex"
import Formily from "../Formily/Formily"

interface Props {
  forms: FormsType[]
  formBackgroundColor?: Color
  spacingSpace?: SpacingSpace
}

function Forms({
  forms,
  formBackgroundColor,
  spacingSpace,
}: Props): ReactElement<Props> | null {
  const { __typename, flatData } = forms[0] as FormsType

  if (typeMatches(__typename, SquidexFormType.FORM)) {
    const { title, id, button, additionalData } =
      flatData as FormType["flatData"]

    return (
      <SurfaceOrg
        color={
          !isEmpty(formBackgroundColor)
            ? formBackgroundColor
            : "backgroundLight"
        }
        shadow="medium"
        spacingSpace={typeof spacingSpace === "number" ? spacingSpace : 6}
      >
        <div>
          {title ? (
            <TextAtm htmlTag="h2" typography="xlarge" pushSpace={4}>
              {title}
            </TextAtm>
          ) : null}

          <Form
            id={id}
            buttonTitle={title}
            buttonText={button[0].text}
            buttonColor={button[0].buttonColor.color}
            additionalData={getAdditionalDataFromSquidex(additionalData || [])}
          />
        </div>
      </SurfaceOrg>
    )
  }

  if (typeMatches(__typename, SquidexFormType.FORM_SALES_MANAGO)) {
    const { link, id } = flatData as FormSalesManago["flatData"]

    return <SalesManagoForm src={link} id={id} />
  }

  const getFormilyFormElement = (
    formilyFormFlatData: FormilyType["flatData"],
    thankYouMessage?: ThankYouMessageElement
  ) => {
    const { formButton, captcha, ...formilyFlatData } = formilyFormFlatData

    const thankYouMessageElement = !isEmpty(thankYouMessage) ? (
      <ThankYouMessage {...thankYouMessage[0].flatData} />
    ) : null

    return (
      <SurfaceOrg
        color={!isEmpty(formBackgroundColor) ? formBackgroundColor : undefined}
        spacingSpace={typeof spacingSpace === "number" ? spacingSpace : 6}
        shadow="small"
      >
        <div>
          <Formily
            button={formButton}
            captchaEnabled={captcha}
            customThankYouPage={thankYouMessageElement}
            {...formilyFlatData}
          />
        </div>
      </SurfaceOrg>
    )
  }

  if (typeMatches(__typename, SquidexFormType.FORMILY_FORM)) {
    return getFormilyFormElement(flatData as FormilyType["flatData"])
  }

  if (typeMatches(__typename, SquidexFormType.FORMILY_FORM_WITH_THANK_YOU)) {
    const { formilyForm, thankYouMessage } =
      flatData as FormilyWithThankYou["flatData"]

    return getFormilyFormElement(formilyForm[0].flatData, thankYouMessage)
  }

  return null
}

export default Forms
