import type { AxiosResponse } from "axios"
import isEmpty from "~/lib/isEmpty"
import { parse } from "~/lib/semver"
import { HEADER_APP_VERSION } from "./index"

function dispatchEvent(name: string, detail: unknown) {
  const event = new CustomEvent(name, {
    detail,
  })

  window.document.dispatchEvent(event)
}

export function appVersionInterceptor(response: AxiosResponse): AxiosResponse {
  const version = response.headers[HEADER_APP_VERSION]

  if (!isEmpty(version)) {
    dispatchEvent("api_version_changed", {
      version: parse(version),
    })
  }

  return response
}
