import queryString from "query-string"
import { STORAGE } from "~/lib/storage"
import SessionStorage from "~/lib/storage/SessionStorage"
import isEmpty from "./isEmpty"
import vars from "./onestoreVars"

export function getQueryParam(
  name: string,
  url: string | null = null
): string | null {
  if (!url) {
    url = window.location.href
  }
  name = name.replace(/[\[\]]/g, "\\$&")
  const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`)
  const results = regex.exec(url)

  if (!results) {
    return null
  }

  if (!results[2]) {
    return ""
  }

  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export interface OnestoreVarsUrl {
  basketUrl: string
  bonusUrl: string
  domainsBonusUrl: string
  domainSearchUrl: string
  failedUrl: string
  finalizeUrl: string
  helpUrl: string
  passwordRecoverUrl: string
  priceListPath: string
  transferPagePath: string
  successfulUrl: string
  wikiUrl: string
  helpContactUrl: string
}

export interface BonusUrl {
  p?: string | PlanSettings
  quantity?: number
  ax?: (string | PlanSettings)[]
  b?: string
  u?: string
  code?: string | null
  period?: string
  domainSearch?: string | null
  d?: string
  checkedPeriod?: string
  checkoutFastMode?: string
}

export interface OnestoreUrl {
  generateBonusUrl: { (params: BonusUrl, externalHost?: string): string }
  getBasketUrl: { (): string }
  getFinalizeUrl: { (): string }
  getFailureUrl: { (): string }
  getSuccessfulUrl: { (): string }
  getDomainSearchUrl: { (): string | null }
  getPasswordRecoverUrl: { (): string }
  getPanelUrl: { (): string }
  getBonusUrl: { (): string }
  getDomainsBonusUrl: { (): string | null }
}

export interface PlanSettings {
  alias: string
  quantity?: number
  parameters?: Record<string, string>
  resources?: Record<string, string>
  period?: string
}

export function getSettingsString(plan: string | PlanSettings): string {
  if (typeof plan === "string") {
    return plan
  }

  const planParams: string[] = []

  for (const paramName in plan.parameters) {
    planParams.push(`param[${paramName}]:${plan.parameters[paramName]}`)
  }

  for (const paramName in plan.resources) {
    planParams.push(`resource[${paramName}]:${plan.resources[paramName]}`)
  }

  if (plan.period) {
    planParams.push(`period:${plan.period}`)
  }

  if (plan.quantity) {
    planParams.push(`quantity:${plan.quantity}`)
  }

  return `${plan.alias}(${planParams.join(";")})`
}

export type BonusUrlParams = Omit<BonusUrl, "p" | "checkoutFastMode"> & {
  p?: string
  checkoutMode?: string
}

const generateBonusUrl = (
  { p, ax, b, u, code, period, domainSearch, d, checkoutFastMode }: BonusUrl,
  externalHost?: string
): string => {
  const params: BonusUrlParams = {}

  if (p) {
    params.p = getSettingsString(p)
  }

  if (b) {
    params.b = b
  }

  if (u) {
    params.u = u
  }

  if (code) {
    params.code = code
  }

  if (period) {
    // Gdy przekierowanie jest z kodem promocyjnym wymuszamy wyświetlenie tylko jednego okresu
    if (code) {
      params.period = period
    } else {
      params.checkedPeriod = period
    }
  }

  if (checkoutFastMode) {
    params.checkoutMode = checkoutFastMode
  }

  if (domainSearch) {
    params.domainSearch = domainSearch
  }

  if (ax) {
    const upsell: string[] = []

    ax.forEach((value: string | PlanSettings) =>
      upsell.push(getSettingsString(value))
    )

    if (upsell.length) {
      params.ax = upsell
    }
  }

  if (d) {
    params.d = d
  }

  const query = queryString.stringify(params, { arrayFormat: "index" })

  if (externalHost) {
    return `${externalHost.replace(/\/+$/, "")}/?${query}`
  }

  return `${vars.bonusUrl}?${query}`
}

const getBasketUrl = (): string => vars.basketUrl

const getDomainsBonusUrl = (): string | null => vars.domainsBonusUrl || null

const getFinalizeUrl = (): string => vars.finalizeUrl

const getFailureUrl = (): string => vars.failedUrl

const getSuccessfulUrl = (): string => vars.successfulUrl

const getDomainSearchUrl = (): string | null => vars.domainSearchUrl || null

const getPasswordRecoverUrl = (): string => vars.passwordRecoverUrl

const getPanelUrl = () =>
  SessionStorage.get(STORAGE.CONTROL_PANEL) || vars.panelUrl

const getBonusUrl = () => vars.bonusUrl || ""

export function getLinkPropForUrl(url?: string): {
  href?: string
  to?: string
} {
  if (url === undefined || url.includes("://")) {
    return {
      href: url,
    }
  }

  return {
    to: url,
  }
}

export function getPromptUrl(hash: string): string {
  const match = hash.match(/p:([^,]*)/)

  return !isEmpty(match) ? match[1] : ""
}

export default {
  generateBonusUrl,
  getBasketUrl,
  getFinalizeUrl,
  getFailureUrl,
  getSuccessfulUrl,
  getDomainSearchUrl,
  getPasswordRecoverUrl,
  getPanelUrl,
  getBonusUrl,
  getDomainsBonusUrl,
}
