import type { ReactElement } from "react"
import React, { useCallback } from "react"
import _t from "@core/i18n"
import { formatPrice } from "@core/pricing"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { DomainBundlePricing } from "@onestore/api/types"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import DomainSearchResultItemMol from "@onestore/hel/dist/components/molecules/DomainSearchResultItemMol"
import { getBundleAdditionalBox } from "@gatsby-plugin-domain-search/components/CategoryResults/BundleItem"
import useAXDomainBundleQuery from "@gatsby-plugin-domain-search/hooks/useAXDomainBundleQuery"
import useDomainActionsDispatch from "@gatsby-plugin-domain-search/hooks/useDomainActionsDispatch"
import type { DomainSearch } from "@gatsby-plugin-domain-search/types"
import { getBrand } from "~/lib/config"

type BundleRowProps = {
  pricing: null | DomainBundlePricing
  domains: DomainCheck.Result[]
  buttonId: string
  isLoading: boolean
  isPartialBundleInBasket: boolean
}

export default function BundleRow({
  isLoading,
  buttonId,
  domains,
  pricing,
  isPartialBundleInBasket,
}: BundleRowProps): ReactElement | null {
  const { addDomainsToBasket } = useDomainActionsDispatch()
  const axBundleData = useAXDomainBundleQuery()

  const insertedDomains: DomainSearch.DomainFqdnWithPlan[] = domains.map(
    (domain): DomainSearch.DomainFqdnWithPlan => {
      return {
        fqdn: domain.fqdn,
        plan: domain.plan_id,
      }
    }
  )

  const mainDomains =
    getBrand() === "home" ? insertedDomains : insertedDomains.slice(0, 1)
  const childDomains = getBrand() === "home" ? [] : insertedDomains.slice(1)

  const onClick = useCallback(() => {
    addDomainsToBasket(mainDomains, childDomains, buttonId, false)
  }, [insertedDomains, isPartialBundleInBasket])

  if (pricing === null) {
    return null
  }

  const period = pricing.period_value
    ? ` / ${_t(`periodName.searchDomainPrice.${pricing.period_type}`, {
        smart_count: pricing.period_value,
      })}`
    : ""

  return (
    <DomainSearchResultItemMol
      domain={domains.map((domain) => ({
        name: domain.name,
        extension: `.${domain.extension}`,
      }))}
      price={
        <TextAtm typography="medium3_s">
          {_t("domainSearch.total")} {formatPrice(pricing.register_price_gross)}{" "}
          <TextAtm typography="xsmall1">{period}</TextAtm>
        </TextAtm>
      }
      isLoading={isLoading}
      button={{
        text: _t("domainSearch.button.addToCart"),
        title: _t("domainSearch.button.addToCart"),
        onClick,
      }}
      caption={
        <LabelAtm size="small" variant="notifyLight">
          {axBundleData.title || _t("domainSearch.groupHeaderText")}
        </LabelAtm>
      }
      additionalBox={getBundleAdditionalBox(axBundleData)}
    />
  )
}
