import type { OptionMapping } from "@gatsby-plugin-marketplace/fragments/optionMapping"
import type { SaleConfiguration as SaleConfigurationType } from "~/fragments/saleConfigurations"

export default function findProductOptions(
  currentPlanSettings: OptionMapping | null,
  saleConfiguration: SaleConfigurationType
): OptionMapping | undefined | null {
  const optionsMapping = saleConfiguration.flatData.optionsMapping || []

  return (
    currentPlanSettings &&
    optionsMapping.find((option) => option.value === currentPlanSettings.value)
  )
}
