import type { ReactElement, SyntheticEvent } from "react"
import { useState } from "react"
import React from "react"
import _t from "@core/i18n"
import AiRandomIcon from "@onestore-icons/ai-random.svg"
import AiIcon from "@onestore-icons/ai.svg"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import IconAtm from "@onestore/hel/dist/components/atoms/IconAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import UnderlineLinkAtm from "@onestore/hel/dist/components/atoms/UnderlineLinkAtm"
import PromptMol from "@onestore/hel/dist/components/molecules/PromptMol"
import BreakpointVisibilityOrg from "@onestore/hel/dist/components/organisms/BreakpointVisibilityOrg"
import FlexContainerOrg from "@onestore/hel/dist/components/organisms/FlexContainerOrg"
import FlexItemOrg from "@onestore/hel/dist/components/organisms/FlexItemOrg"
import OverlayContainerOrg, {
  headerTypes,
  sizes,
  startPositions,
} from "@onestore/hel/dist/components/organisms/OverlayContainerOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import type Theme from "@onestore/hel/dist/typings/theme"
import useDomainSearchAiFooterModalContentQuery from "@gatsby-plugin-domain-search/hooks/useDomainSearchAiFooterModalContentQuery"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import MarkdownTitle from "@gatsby-plugin-generic-page/components/Markdown/Title"
import getBaseMarkdownOverrides from "@gatsby-plugin-generic-page/helpers/getBaseMarkdownOverrides"
import ModalContainer from "~/components/ModalContainer"
import isEmpty from "~/lib/isEmpty"

function Header(): ReactElement {
  return (
    <>
      <BreakpointVisibilityOrg from="small" to="medium">
        <PushOrg
          leftSpace={2}
          bottomSpace={1.5}
          topSpace={1.5}
          rightSpace={2}
          hasNoReset
        >
          <TextAtm typography="medium2_h" emphasis="medium">
            {_t("domainSearch.promptHeaderText")}
          </TextAtm>
        </PushOrg>
      </BreakpointVisibilityOrg>

      <BreakpointVisibilityOrg from="medium">
        <PushOrg leftSpace={2} bottomSpace={2} topSpace={2} hasNoReset>
          <FlexContainerOrg alignItems="center">
            <FlexItemOrg>
              <IconAtm icon={AiIcon} sizeSpace={2.5} color="violet" />
            </FlexItemOrg>

            <TextAtm
              typography="medium2_h"
              pushSpace={{ left: 2 }}
              emphasis="medium"
            >
              {_t("domainSearch.promptHeaderText")}
            </TextAtm>
          </FlexContainerOrg>
        </PushOrg>
      </BreakpointVisibilityOrg>
    </>
  )
}

type ButtonsProps = {
  onSubmitButtonClick: () => void
  isPending: boolean
  onRandomizePrompt: () => void
}

function Buttons({
  onSubmitButtonClick,
  isPending,
  onRandomizePrompt,
}: ButtonsProps): ReactElement<ButtonsProps> {
  return (
    <PushOrg bottomSpace={1} leftSpace={1} topSpace={1} hasNoReset>
      <FlexContainerOrg justifyContent="flex-end">
        <PushOrg rightSpace={3} hasNoReset>
          <BreakpointVisibilityOrg from="small" to="medium">
            <ButtonAtm
              title={_t("domainSearch.promptRandomButton")}
              variant="transparent"
              color="violet"
              icon={AiRandomIcon}
              isLoading={isPending}
              isDisabled={isPending}
              onClick={onRandomizePrompt}
            />
          </BreakpointVisibilityOrg>

          <BreakpointVisibilityOrg from="medium">
            <ButtonAtm
              title={_t("domainSearch.promptRandomButton")}
              variant="text"
              color="violet"
              icon={AiRandomIcon}
              isLoading={isPending}
              isDisabled={isPending}
              onClick={onRandomizePrompt}
            >
              {_t("domainSearch.promptRandomButton")}
            </ButtonAtm>
          </BreakpointVisibilityOrg>
        </PushOrg>

        <ButtonAtm
          title={_t("domainSearch.promptSubmitButton")}
          color="violet"
          isLoading={isPending}
          isDisabled={isPending}
          isWider
          onClick={onSubmitButtonClick}
          isExpandedOnMobile
        >
          {_t("domainSearch.promptSubmitButton")}
        </ButtonAtm>
      </FlexContainerOrg>
    </PushOrg>
  )
}

type FooterWithModalProps = {
  modalContent: string
}

function FooterWithModal({
  modalContent,
}: FooterWithModalProps): ReactElement<FooterWithModalProps> {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <>
      <PushOrg leftSpace={1.5} bottomSpace={1} topSpace={2} hasNoReset>
        <TextAtm typography="tiny1">
          {_t("domainSearch.promptFooterText")}{" "}
          <UnderlineLinkAtm
            onClick={() => setIsOpen(true)}
            title={_t("domainSearch.promptFooterModalTitle")}
            typography="tiny2"
          >
            {_t("domainSearch.promptFooterModalTitle")}
          </UnderlineLinkAtm>
        </TextAtm>
      </PushOrg>

      {isOpen ? (
        <ModalContainer>
          <OverlayContainerOrg
            size={sizes.MEDIUM}
            startPosition={startPositions.RIGHT}
            headerType={headerTypes.SHADOWED}
            backgroundColor="background"
            header={
              <MarkdownTitle
                overrides={getBaseMarkdownOverrides({
                  typography: "large2",
                })}
              >
                {_t("domainSearch.promptFooterModalTitle")}
              </MarkdownTitle>
            }
            headerWidth="default"
            onCloseButtonClick={() => setIsOpen(false)}
          >
            <MarkdownText>{modalContent}</MarkdownText>
          </OverlayContainerOrg>
        </ModalContainer>
      ) : null}
    </>
  )
}

type DomainSearchPromptProps = {
  value: string
  isPending: boolean
  setValue: { (value: string): void }
  onSubmitButtonClick: { (): void }
  onRandomizePrompt: { (): void }
  backgroundColor?: Theme.BackgroundColor
  surfaceColor?: Theme.BackgroundColor
}

function DomainSearchPrompt({
  value,
  isPending,
  backgroundColor,
  surfaceColor,
  setValue,
  onSubmitButtonClick,
  onRandomizePrompt,
}: DomainSearchPromptProps): ReactElement<DomainSearchPromptProps> {
  const { footerModalContent } = useDomainSearchAiFooterModalContentQuery()

  return (
    <PromptMol
      header={<Header />}
      textArea={{
        heightSpace: 4,
        value,
        onChange: (event: SyntheticEvent<HTMLTextAreaElement>) => {
          setValue(event.currentTarget.value)
        },
        backgroundColor: backgroundColor,
        typography: "medium1_h",
      }}
      textAreaButtons={
        <Buttons
          onSubmitButtonClick={onSubmitButtonClick}
          isPending={isPending}
          onRandomizePrompt={onRandomizePrompt}
        />
      }
      footer={
        !isEmpty(footerModalContent) ? (
          <FooterWithModal modalContent={footerModalContent} />
        ) : null
      }
      surfaceColor={{ color: surfaceColor || "backgroundLight" }}
    />
  )
}

export default DomainSearchPrompt
