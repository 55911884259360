import type { ReactElement, SyntheticEvent } from "react"
import React, { useState } from "react"
import _t from "@core/i18n"
import get from "lodash/get"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import Forms from "@gatsby-plugin-generic-page/components/Forms"
import { getDomainsPoolId } from "@gatsby-plugin-generic-page/helpers/getDomainsPoolId"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import type { OptionMapping } from "@gatsby-plugin-marketplace/fragments/optionMapping"
import type { ParametersTableProps } from "~/components/ParametersTable"
import ParametersTable from "~/components/ParametersTable"
import useCurrentProducts from "~/components/ParametersTable/useCurrentProducts"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import useLinkProperties from "~/hooks/useLinkProperties"
import useModalState from "~/hooks/useModalState"
import useProductSettings from "~/hooks/useProductSettings"
import isEmpty from "~/lib/isEmpty"
import url from "~/lib/url"
import type { Forms as FormsType } from "~/types/squidex"
import type { ComparableProductsPackage } from "~/types/squidex"
import ModalContainer from "../ModalContainer"
import ModalTemplateWrapper from "../ModalTemplateWrapper"
import { ParametersFooterWithForm } from "./ParametersFooter"

export type ProductParametersProps = {
  saleConfiguration?: SaleConfiguration
  hasHiddenFooter?: boolean
  packages?: ComparableProductsPackage[]
} & Omit<
  ParametersTableProps,
  "generateHeaderLink" | "handleClickFormButton" | "headerColumns"
>

export default function ProductParameters({
  saleConfiguration,
  products,
  packages,
  tableHeaderTitle,
  showOnlyDifferences,
  hasPromoPrice,
  hasRenewalPrice,
  hasHiddenGroupLabel,
  hasHiddenFooter,
  hasTogglePriceType,
  showOnlyTwoColumns,
}: ProductParametersProps): ReactElement<ProductParametersProps> {
  const { isOpen, open, close } = useModalState()
  const [activeFormData, setActiveFormData] = useState<FormsType | null>(null)

  const productId = saleConfiguration?.id || ""

  const handleClickFormButton = (event: SyntheticEvent, form: FormsType) => {
    event.preventDefault()
    setActiveFormData(form)
    open()
  }

  const generateHeaderLink = (
    item: ComparableProduct,
    comparableProducts: ComparableProduct[]
  ) => {
    const {
      flatData: { name, saleConfigurations, modalForm, buttonText },
    } = item

    const isActive =
      saleConfigurations && saleConfigurations.length
        ? saleConfigurations[0].id === productId
        : false

    if (isEmpty(saleConfigurations) || !isEmpty(modalForm)) {
      return {
        link: !isEmpty(modalForm)
          ? {
              onClick: (event) => handleClickFormButton(event, modalForm[0]),
              text: buttonText,
            }
          : undefined,
        title: name,
        isActive: comparableProducts.length > 1 ? isActive : false,
      }
    }

    let productOptions: OptionMapping | null = null

    const defaultCloudBluePlanData: CloudBluePlan["flatData"] | undefined = get(
      saleConfigurations[0],
      "flatData.defaultCloudBluePlan[0].flatData"
    )

    if (saleConfigurations[0].id === productId) {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      productOptions = useProductSettings(saleConfigurations[0])
    }

    const continueUrl = url.generateBonusUrl({
      p: defaultCloudBluePlanData?.alias,
      ax: getUpsellData(saleConfigurations[0].flatData.upsell),
      period: !isEmpty(productOptions)
        ? productOptions.period?.period
        : undefined,
      domainSearch: !isEmpty(saleConfigurations)
        ? getDomainsPoolId(saleConfigurations[0])
        : undefined,
    })

    /* eslint-disable react-hooks/rules-of-hooks */
    const linkProperties = useLinkProperties(continueUrl)

    return {
      title: name,
      isActive,
      link: url
        ? {
            ...linkProperties,
            text: _t("marketplace.chooseButton"),
            rel: "nofollow",
          }
        : undefined,
    }
  }

  const [currentProducts, headerColumns] = useCurrentProducts(
    products,
    packages,
    showOnlyTwoColumns
  )

  return (
    <>
      <ParametersTable
        products={currentProducts}
        headerColumns={headerColumns}
        saleConfiguration={saleConfiguration}
        tableHeaderTitle={tableHeaderTitle}
        generateHeaderLink={generateHeaderLink}
        showOnlyDifferences={showOnlyDifferences}
        hasPromoPrice={hasPromoPrice}
        hasRenewalPrice={hasRenewalPrice}
        hasHiddenGroupLabel={hasHiddenGroupLabel}
        footer={
          hasHiddenFooter
            ? undefined
            : currentProducts.map((item) => (
                <ParametersFooterWithForm
                  key={item.id}
                  item={item}
                  handleClickFormButton={handleClickFormButton}
                  productId={productId}
                />
              ))
        }
        showOnlyTwoColumns={showOnlyTwoColumns}
        hasTogglePriceType={hasTogglePriceType}
      />

      {isOpen && !isEmpty(activeFormData) ? (
        <ModalContainer>
          <ModalTemplateWrapper
            onCloseButtonClick={close}
            width="narrow"
            isOpen={isOpen}
            content={
              <Forms
                forms={[activeFormData]}
                spacingSpace={0}
                formBackgroundColor="backgroundLight"
              />
            }
          />
        </ModalContainer>
      ) : null}
    </>
  )
}
