import { isNumber } from "lodash"
import type { BasketLastItem } from "@onestore/api/basket"
import type { BasketStateItem } from "@onestore/onestore-store-common"
import type { BasketActionSource } from "@gatsby-plugin-basket/store/actions"
import type { BasketViewItem } from "@gatsby-plugin-basket/types"
import isEmpty from "./isEmpty"
import SessionStorage from "./storage/SessionStorage"

const SOURCE_KEY = "source"

export type SourceObject = {
  planAlias: string
  source: BasketActionSource
}

export function isItemEnabled(item: BasketStateItem | BasketViewItem): boolean {
  return !isNumber(
    (item as BasketStateItem).disabled_reason ||
      (item as BasketViewItem).disabledReason
  )
}

export function setAddCartSource(
  planAlias: SourceObject["planAlias"],
  source: SourceObject["source"]
): void {
  SessionStorage.set(
    SOURCE_KEY,
    JSON.stringify({
      planAlias,
      source,
    })
  )
}

export function getAddCartSource(
  products: BasketLastItem[]
): BasketActionSource | null {
  const source = SessionStorage.get(SOURCE_KEY)

  if (isEmpty(source)) {
    return null
  }

  const sourceObject: SourceObject = JSON.parse(source)

  const searchedProducts = products.find(
    (product) => product.alias === sourceObject.planAlias
  )

  SessionStorage.remove(SOURCE_KEY)

  return !isEmpty(searchedProducts) ? sourceObject.source : null
}
