import type { OptionMapping } from "@gatsby-plugin-marketplace/fragments/optionMapping"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import { getSelectedPlanSettings } from "../utils"
import useSaleConfigurationState from "./useSaleConfigurationState"

export default function useProductSettings(
  saleConfiguration: SaleConfiguration
): OptionMapping | null {
  const { options } = useSaleConfigurationState(saleConfiguration.id)

  return getSelectedPlanSettings(
    saleConfiguration.flatData.optionsMapping || [],
    options
  )
}
