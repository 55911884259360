import { graphql, useStaticQuery } from "gatsby"
import type { CustomTextColors } from "@gatsby-plugin-generic-page/fragments/customColor"
import type { GaEvent } from "~/fragments/gaEvent"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import type {
  CustomBackgroundColor,
  Pages,
  SquidexMarkdownEmbeddedContent,
} from "~/types/squidex"

export enum BackgroundType {
  COLOR = "Kolor",
  IMAGE = "Obrazek w tle",
}

export interface NotificationBar {
  version: number
  id: string
  flatData: {
    name: string
    type: string
    content: SquidexMarkdownEmbeddedContent
    backgroundColor?: CustomBackgroundColor
    backgroundType?: BackgroundType.COLOR | BackgroundType.IMAGE
    period: {
      from: string
      to?: string
    }[]
    priority: number
    link: {
      title: string
      pages: Pages
      url?: string
      anchorId?: string
    }[]
    modal:
      | {
          title: string
          modalText: string
        }[]
      | null
    textColor: keyof typeof CustomTextColors
    displayOnProductPages?: boolean
    displayOnCheckout?: boolean
    backgroundImage?: EmbeddedAssetSquidexMetadata[]
    gaEvent?: GaEvent
  }
}

interface Query {
  GraphCmsSquidex: {
    queryNotificationContents: NotificationBar[]
  }
}

export default function useNotificationBarQuery(): NotificationBar[] {
  const { GraphCmsSquidex } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        queryNotificationContents {
          version
          id
          flatData {
            name
            type
            content {
              text
              contents {
                ... on GraphCmsSquidex_CtaElement {
                  ...CtaElement
                }
                ... on GraphCmsSquidex_Counter {
                  ...Counter
                }
              }
            }
            period {
              from
              to
            }
            priority
            link {
              title
              url
              pages {
                ... on GraphCmsSquidex_GenericPage {
                  id
                  flatData {
                    url
                  }
                }
                ... on GraphCmsSquidex_Page {
                  id
                  flatData {
                    url
                  }
                }
              }
              anchorId
            }
            modal {
              title
              modalText
            }
            displayOnProductPages
            displayOnCheckout
            backgroundImage {
              ...Image
            }
            textColor
            backgroundType
            backgroundColor {
              ... on GraphCmsSquidex_BackgroundColorComponent {
                __typename
                color
              }
              ... on GraphCmsSquidex_GradientComponent {
                __typename
                start
                end
              }
            }
            gaEvent {
              ...GaEvent
            }
          }
        }
      }
    }
  `)

  return GraphCmsSquidex.queryNotificationContents
}
