import { get } from "lodash"
import type { SaleConfiguration } from "@gatsby-plugin-definitions/lib/sale-configuration"
import type { AppState } from "~/store/reducer"

export type SaleConfigurationState = {
  options: SaleConfiguration.Options
  values: Record<string, string>
  controls: SaleConfiguration.Control[]
}

export type MarketplaceState = {
  readonly configuration: Record<string, SaleConfigurationState>
}

export const initialState: MarketplaceState = {
  configuration: {},
}

export const getSaleConfiguration = (
  state: AppState,
  uuid: string
): SaleConfigurationState => get(state, ["marketplace", "configuration", uuid])
