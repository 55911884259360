import { getCurrencyCode, getGA4Price } from "@core/pricing"
import { globalHistory } from "@reach/router"
import { forEach, values } from "lodash"
import type { BasketLastItem, BasketResponse } from "@onestore/api/basket"
import type { BasketProductItem } from "@onestore/onestore-store-common"
import {
  getBasketItemIndex,
  getLastItemsFromBasket,
} from "@gatsby-plugin-basket/store/utils"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { GaEvent } from "~/fragments/gaEvent"
import type {
  AiSearchEvent,
  CardItem,
  CmsObjectEvent,
  ContentShowEvent,
  EcommerceAddProductGA4DataLayer,
  EcommerceBasketBeginCheckoutGA4DataLayer,
  EcommerceBasketChangePaymentGA4DataLayer,
  EcommerceBasketShowGA4DataLayer,
  EcommerceOrderGA4DataLayer,
  EcommerceProductRemoveGA4DataLayer,
  EcommerceSelectDomainGA4DataLayer,
  EcommerceSelectItemGA4DataLayer,
  FaqShowEvent,
  MenuViewEvent,
  ProductBoxClickEvent,
  SiteSearchResultGa4DataLayer,
  SiteSearchResultStatus,
  ToplayerShowEvent,
  TransactionProduct,
  UserEvent,
} from "~/lib/dataLayer"
import { dataLayerPush } from "~/lib/dataLayer"
import type { TrackingContextType, TrackingItem } from "~/lib/tracking"
import { Tracking } from "~/lib/tracking"
import { getAddCartSource } from "./basket"
import type { FormEvent } from "./dataLayer"

const ECOMMERCE_TRACK_ITEMS_WITHOUT_SKU = true

export enum EventCategory {
  FORM_VIEW = "form_view",
  FORM_SUBMIT = "form_submit",
  FORM_START = "form_start",
  ADD_TO_CART = "add_to_cart",
  ORDER = "purchase",
  CART = "view_cart",
  REMOVE_FROM_CART = "remove_from_cart",
  DOMAIN_SEARCH_FILTERS = "DomainSearchFilters",
}

export function sendEvent(params: GaEvent) {
  dataLayerPush<CmsObjectEvent>({
    event: "cms_object",
    event_type: "ux_interaction",
    object_category: params.eventCategory,
    object_name: params.eventAction,
  })
}

export function sendGaEvent(gaEvent?: GaEvent) {
  return gaEvent ? () => sendEvent(gaEvent) : undefined
}

export function sendUserLoginEvent(user_id: string | null): void {
  dataLayerPush<UserEvent>({
    event: "login",
    event_type: "user_interaction",
    user_id,
  })
}

export function sendUserRegisterEvent(): void {
  dataLayerPush<UserEvent>({
    event: "register",
    event_type: "user_interaction",
  })
}

export function sendMenuViewEvent() {
  dataLayerPush<MenuViewEvent>({
    event: "menu_view",
    event_type: "ux_interaction",
  })
}

export function sendFaqShowEvent(faqSubject: string) {
  dataLayerPush<FaqShowEvent>({
    event: "faq_show",
    event_type: "ux_interaction",
    faq_subject: faqSubject,
  })
}

export function sendContentShowEvent() {
  dataLayerPush<ContentShowEvent>({
    event: "content_show",
    event_type: "ux_interaction",
  })
}

export function sendToplayerShowEvent(toplayer_name?: string) {
  dataLayerPush<ToplayerShowEvent>({
    event: "toplayer_show",
    event_type: "ux_interaction",
    toplayer_name: toplayer_name || null,
  })
}

export function sendGAFormEvent({
  event,
  form_type,
  form_id,
}: Omit<FormEvent, "event_type" | "form_provider">): void {
  dataLayerPush<FormEvent>({
    event,
    event_type: "form_interaction",
    form_type: form_type || "contents",
    form_provider: "inner",
    form_id,
  })
}

export function sendSiteSearchResultEvent(
  search_term: string,
  status?: SiteSearchResultStatus
): void {
  dataLayerPush<SiteSearchResultGa4DataLayer>({
    event: "site_search_result",
    event_type: "site_search_interaction",
    search_term: search_term,
    status: status,
  })
}

export const getLastItemsProductsData = (
  items: BasketLastItem[],
  cart_id: string | null,
  basketIndex: number
): CardItem[] => {
  const result: Record<string, CardItem> = {}

  let index = 0

  const addItemToResult = (context: TrackingContextType) => {
    const { item, name, category } = context

    if (!item.sku && !ECOMMERCE_TRACK_ITEMS_WITHOUT_SKU) {
      return
    }

    result[index] = createTrackedItemGA4({
      ...item,
      name,
      category,
      index: basketIndex,
      cart_id,
    })

    index++
  }

  items.forEach((item) => {
    addItemToResult(Tracking.getTrackingContext(item))
  })

  return values(result)
}

export const getProductsGA4 = (
  items: BasketProductItem[],
  event: EventCategory | null = null,
  cart_id: string
): CardItem[] => {
  const result: Record<string, CardItem> = {}
  const onlyPatchedItems = event === EventCategory.ADD_TO_CART

  let basketIndex = 0

  const addItemToResult = (context: TrackingContextType) => {
    const { item, name, category } = context

    if (!item.sku && !ECOMMERCE_TRACK_ITEMS_WITHOUT_SKU) {
      return
    }

    if (onlyPatchedItems && !item.last_patch_operation_flag) {
      return
    }

    result[basketIndex] = createTrackedItemGA4({
      ...item,
      name,
      category,
      index: basketIndex,
      cart_id,
    })

    basketIndex++

    if ("children" in item && item.children.length > 0) {
      forEach<TrackingItem>(item.children, (childItem) => {
        addItemToResult(Tracking.getTrackingContext(childItem, item))
      })
    }
  }

  forEach(items, (item) => {
    addItemToResult(Tracking.getTrackingContext(item))
  })

  return values(result)
}

export const getBasketLastItemIndex = (items: BasketProductItem[]): number => {
  let index = 0

  const addItemToResult = (basketItem: BasketProductItem) => {
    index++

    if ("children" in basketItem && basketItem.children.length > 0) {
      forEach<BasketProductItem>(basketItem.children, (childItem) => {
        addItemToResult(childItem)
      })
    }
  }

  forEach(items, (item) => {
    addItemToResult(item)
  })

  return index
}

const createTrackedItemGA4 = (itemData): CardItem => {
  return {
    id: itemData.itemId ? itemData.itemId : itemData.id,
    item_id: itemData.sku ? itemData.sku : itemData.name,
    item_name: itemData.name,
    affiliation: globalHistory.location.hostname
      ? globalHistory.location.hostname
      : undefined,
    index: null,
    cart_index: itemData.index,
    currency: getCurrencyCode(),
    item_category: itemData.category,
    item_variant: itemData.period_name,
    price: getGA4Price(itemData.price),
    quantity: 1,
    cart_id: itemData.cart_id,
    product_plan_id: itemData.planId || itemData.plan_id || null,
  }
}

export const orderProceedEvent = (
  transaction_id: string,
  totalValue: number,
  basketOrderedItems: BasketProductItem[],
  basketToken: string,
  paymentType: number,
  systemId?: string
) => {
  dataLayerPush<EcommerceOrderGA4DataLayer>({
    event: "purchase",
    event_type: "ecommerce",
    ecommerce: {
      transaction_id,
      value: getGA4Price(totalValue),
      currency: getCurrencyCode(),
      payment_type: paymentType,
      system_id: systemId,
      items: getProductsGA4(
        basketOrderedItems,
        EventCategory.ORDER,
        basketToken
      ),
    },
  })
}

export const basketClearItems = (
  cart_id: string,
  basketItems: BasketProductItem[]
) => {
  const orderedProducts = getProductsGA4(basketItems, null, cart_id)

  const items = orderedProducts.map((product) => {
    return {
      item_id: product.item_id,
      item_name: product.item_name,
      currency: getCurrencyCode(),
      price: product.price,
      quantity: product.quantity,
      index: null,
      cart_index: product.id
        ? getBasketItemIndex(orderedProducts, product.id)
        : undefined,
      affiliation: product.affiliation,
      item_category: product.item_category,
      item_variant: product.item_variant,
      cart_id: product.cart_id,
    }
  })

  dataLayerPush<EcommerceProductRemoveGA4DataLayer>({
    event: "remove_from_cart",
    event_type: "ecommerce",
    ecommerce: {
      items,
    },
  })
}

export const basketItemRemoved = (
  cart_id: string,
  basketOrderedItems: BasketProductItem[],
  id: number,
  item_id: string,
  item_name: string,
  price: number,
  quantity: number | undefined,
  affiliation: string | undefined,
  item_category: string | undefined,
  item_variant: string | undefined
) => {
  const orderedProducts = getProductsGA4(
    basketOrderedItems,
    EventCategory.REMOVE_FROM_CART,
    cart_id
  )
  const itemRemovedIndex = getBasketItemIndex(orderedProducts, id)

  dataLayerPush<EcommerceProductRemoveGA4DataLayer>({
    event: "remove_from_cart",
    event_type: "ecommerce",
    ecommerce: {
      items: [
        {
          item_id,
          item_name,
          currency: getCurrencyCode(),
          price: getGA4Price(price),
          quantity,
          index: null,
          cart_index: itemRemovedIndex,
          ...(affiliation && {
            affiliation,
          }),
          ...(item_category && {
            item_category,
          }),
          ...(item_variant && {
            item_variant,
          }),
          ...(cart_id && {
            cart_id,
          }),
          product_plan_id:
            basketOrderedItems[itemRemovedIndex].planId || undefined,
        },
      ],
    },
  })
}

export const basketAddItemsResultsEvent = (
  basket: BasketResponse,
  basketOrderedItems: BasketProductItem[]
) => {
  const products: TransactionProduct[] = Tracking.getProducts(
    basket.items,
    EventCategory.ADD_TO_CART
  )

  const addedItems = getLastItemsFromBasket(basket)

  const addCartSource = getAddCartSource(addedItems)

  if (products.length === 0) {
    return
  }

  dataLayerPush<EcommerceAddProductGA4DataLayer>({
    event: "add_to_cart",
    event_type: "ecommerce",
    cart_id: basket.token ? basket.token : null,
    ecommerce: {
      source: addCartSource,
      items: getLastItemsProductsData(
        addedItems,
        basket.token,
        getBasketLastItemIndex(basketOrderedItems)
      ),
    },
  })
}

export const basketShowEvent = (action, items) => {
  dataLayerPush<EcommerceBasketShowGA4DataLayer>({
    event: "view_cart",
    event_type: "ecommerce",
    ecommerce: {
      currency: getCurrencyCode(),
      value: getGA4Price(action.totalValue),
      items: getProductsGA4(items, EventCategory.ORDER, action.token),
    },
  })
}

export const orderCheckoutSummaryEvent = (action, basketOrderedItems) => {
  dataLayerPush<EcommerceBasketBeginCheckoutGA4DataLayer>({
    event: "begin_checkout",
    event_type: "ecommerce",
    ecommerce: {
      currency: getCurrencyCode(),
      value: getGA4Price(action.totalValue),
      items: getProductsGA4(
        basketOrderedItems,
        EventCategory.ORDER,
        action.token
      ),
    },
  })
}

export const changePaymentMethod = (
  value: number,
  payment_type: number,
  system_id: string,
  basketOrderedItems: BasketProductItem[],
  cart_id: string
) => {
  dataLayerPush<EcommerceBasketChangePaymentGA4DataLayer>({
    event: "add_payment_info",
    event_type: "ecommerce",
    ecommerce: {
      currency: getCurrencyCode(),
      value: getGA4Price(value),
      payment_type,
      system_id,
      items: getProductsGA4(basketOrderedItems, EventCategory.ORDER, cart_id),
    },
  })
}

export const selectItemEvent = (action, priceValue: string | undefined) => {
  dataLayerPush<EcommerceSelectItemGA4DataLayer>({
    event: "select_item",
    event_type: "ecommerce",
    ecommerce: {
      items: [
        {
          item_id: action.saleConfigurations[0].id,
          item_name: action.name,
          currency: getCurrencyCode(),
          price: priceValue ? parseFloat(priceValue.replace(",", ".")) : null,
          index: null,
          quantity: "1",
          affiliation: globalHistory.location.hostname
            ? globalHistory.location.hostname
            : undefined,
          ...(action.saleConfigurations[0]?.flatData.defaultCloudBluePlan[0]
            ?.flatData.periods[0]?.period_name && {
            item_variant:
              action.saleConfigurations[0].flatData.defaultCloudBluePlan[0]
                .flatData.periods[0].period_name,
          }),
        },
      ],
    },
  })
}

export const selectDomainEvent = (
  periods: CloudBluePeriod[],
  priceValue: string,
  title?: string
) => {
  dataLayerPush<EcommerceSelectDomainGA4DataLayer>({
    event: "select_item",
    event_type: "ecommerce",
    ecommerce: {
      items: [
        {
          item_id: periods[0].id,
          item_name: title ? title : undefined,
          currency: getCurrencyCode(),
          price: parseFloat(priceValue.replace(",", ".")),
          item_category: "domain",
          item_variant: periods[0].period_name,
          index: null,
          quantity: "1",
          affiliation: globalHistory.location.hostname,
        },
      ],
    },
  })
}

export const productBoxClickEvent = ({
  productbox_type,
  productbox_option,
  productbox_checked_item,
  productboxbox_time,
  productboxbox_package,
}: Omit<ProductBoxClickEvent, "event_type" | "event">): void => {
  dataLayerPush<ProductBoxClickEvent>({
    event: "productbox_click",
    event_type: "productbox_interaction",
    productbox_type: productbox_type,
    productbox_option: productbox_option,
    productbox_checked_item: productbox_checked_item,
    productboxbox_time: productboxbox_time,
    productboxbox_package: productboxbox_package,
  })
}

export function sendPremiumButtonEvent(): void {
  dataLayerPush<CmsObjectEvent>({
    event: "cms_object",
    event_type: "ux_interaction",
    object_category: "premium_button",
    object_name: "przejdz_na_gielde_domen",
  })
}

export function sendAiSearchButtonEvent(value: string): void {
  dataLayerPush<AiSearchEvent>({
    event: "site_search_result",
    event_type: "site_ai_search_interaction",
    search_term: value,
    status: "ai",
  })
}

export function sendAiSearchExamplePromptEvent(value: string): void {
  dataLayerPush<CmsObjectEvent>({
    event: "cms_object",
    event_type: "ux_interaction",
    object_category: "ai_search_button",
    object_name: value,
  })
}

export function sendFilterCategoryClickEvent(eventLabel?: string) {
  dataLayerPush<CmsObjectEvent>({
    event: "cms_object",
    event_type: "ux_interaction",
    object_category: EventCategory.DOMAIN_SEARCH_FILTERS,
    object_name: eventLabel || "",
  })
}

export function sendCostCalculatorModalButtonClick(): void {
  dataLayerPush<CmsObjectEvent>({
    event: "cms_object",
    event_type: "ux_interaction",
    object_category: "costs_calculator_start",
    object_name: "costs_calculator_start",
  })
}

export function sendCostCalculatorClickConfig(label: string): void {
  dataLayerPush<CmsObjectEvent>({
    event: "cms_object",
    event_type: "ux_interaction",
    object_category: "costs_calculator_config",
    object_name: label,
  })
}
