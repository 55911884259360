import type { ReactElement } from "react"
import React from "react"
import _t from "@core/i18n"
import type { CenteredDomainSearchSectionFlatData } from "@onestore-graphql/Model"
import Search from "@onestore-icons/search.svg"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import DomainSearchInputTabs from "@gatsby-plugin-domain-search/components/DomainSearchInputTabs"
import DomainSearchPrompt from "@gatsby-plugin-domain-search/components/DomainSearchPrompt"
import useDomainLinksQuery from "@gatsby-plugin-domain-search/hooks/useDomainLinksQuery"
import useDomainSearchPromptInput from "@gatsby-plugin-domain-search/hooks/useDomainSearchPromptInput"
import BaseContainer from "@gatsby-plugin-generic-page/components/BaseContainer"
import BaseSection from "@gatsby-plugin-generic-page/components/BaseSection"
import MarkdownCaption from "@gatsby-plugin-generic-page/components/Markdown/Caption"
import MarkdownText from "@gatsby-plugin-generic-page/components/Markdown/Text"
import MarkdownTitle from "@gatsby-plugin-generic-page/components/Markdown/Title"
import Media from "@gatsby-plugin-generic-page/components/Media"
import { ContainerWidthType } from "@gatsby-plugin-generic-page/fragments/containerSettings"
import DomainInputField from "~/components/DomainInputField"
import DomainLinks from "~/components/DomainLinks"
import isEmpty from "~/lib/isEmpty"

function CenteredDomainSearchSection({
  captionIcon,
  caption,
  title,
  text,
  sectionSettings,
}: CenteredDomainSearchSectionFlatData): ReactElement<CenteredDomainSearchSectionFlatData> {
  const isHeaderPropUsed = [captionIcon, caption, title, text].some(
    (prop) => prop !== undefined
  )
  const domainLinks = useDomainLinksQuery()
  const {
    value,
    setValueToRandomPrompt,
    setValue,
    onSubmitButtonClick,
    isPending,
  } = useDomainSearchPromptInput()

  return (
    <BaseSection {...sectionSettings}>
      <BaseContainer
        {...sectionSettings?.containerSettings}
        containerWidth={
          isEmpty(sectionSettings?.containerSettings?.containerWidth)
            ? undefined
            : ContainerWidthType.MEDIUM
        }
      >
        {isHeaderPropUsed ? (
          <AlignContentOrg horizontalAlign="center" horizontalAlignType="both">
            {captionIcon ? (
              <PushOrg bottomSpace={1.5}>
                <Media media={captionIcon} />
              </PushOrg>
            ) : null}

            {caption ? <MarkdownCaption>{caption}</MarkdownCaption> : null}

            {title ? <MarkdownTitle>{title}</MarkdownTitle> : null}

            {text ? <MarkdownText>{text}</MarkdownText> : null}
          </AlignContentOrg>
        ) : null}

        <PushOrg topSpace={4}>
          <DomainSearchInputTabs
            backgroundColor="background"
            activeTabIndex={0}
            domainSearchInput={
              <DomainInputField
                infoText={_t("domainSearch.inputPlaceholder")}
                submitText={_t("domainSearch.submit")}
                placeholderText={_t("domainSearch.inputPlaceholder")}
                isFancy={false}
                icon={Search}
                hasWiderButton
              />
            }
            domainSearchPrompt={
              <DomainSearchPrompt
                value={value}
                isPending={isPending}
                setValue={setValue}
                onSubmitButtonClick={onSubmitButtonClick}
                onRandomizePrompt={setValueToRandomPrompt}
              />
            }
          />
        </PushOrg>

        {!isEmpty(domainLinks) && (
          <PushOrg topSpace={4}>
            <DomainLinks domainLinks={domainLinks} />
          </PushOrg>
        )}
      </BaseContainer>
    </BaseSection>
  )
}

export default CenteredDomainSearchSection
