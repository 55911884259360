import type { KeyboardEvent, SyntheticEvent } from "react"
import React, { useCallback, useState } from "react"
import Search from "@onestore-icons/search.svg"
import { navigate } from "gatsby"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { IconAtmProps } from "@onestore/hel/dist/components/atoms/IconAtm"
import SubmitInputMol from "@onestore/hel/dist/components/molecules/SubmitInputMol"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { LocationDomainSearchState } from "@gatsby-plugin-domain-search/hooks/useDomainHashChangeEffect"
import { buildDomainHash } from "@gatsby-plugin-domain-search/lib/domains"
import { validatePhrase } from "~/lib/domain"
import url from "~/lib/url"

export interface DomainInputFieldProps {
  isFancy?: boolean
  infoText?: string
  submitText: string
  placeholderText: string
  autoFocus?: boolean
  extension?: DomainCheck.Extension
  afterSubmit?: { (): void }
  icon?: IconAtmProps["icon"]
  hasHiddenBorder?: boolean
  hasWiderButton?: boolean
  shadow?: Theme.BoxShadow
  backgroundColor?: Theme.BackgroundColor
}
export default function DomainInputField({
  isFancy = true,
  infoText,
  submitText,
  placeholderText,
  extension,
  afterSubmit,
  icon,
  autoFocus = false,
  hasHiddenBorder = false,
  hasWiderButton = false,
  shadow,
  backgroundColor,
}: DomainInputFieldProps) {
  const [valid, setValid] = useState(true)
  const [value, setValue] = useState("")
  const [componentText, setError] = useState(placeholderText)

  const onChange = (event: SyntheticEvent<HTMLInputElement>): void => {
    setValue(event.currentTarget.value)
    const validation = validatePhrase(value)
    setValid(validation.valid)
    setError(validation.valid ? placeholderText : validation.errors[0])
  }

  const onSubmit = useCallback((): void => {
    if (value.length === 0 || !valid) {
      return
    }
    const hash = buildDomainHash({
      d: value,
      e: extension,
    })

    navigate(url.getDomainSearchUrl() || "", {
      state: {
        hash,
        rawSearchValue: value,
      } as LocationDomainSearchState,
      replace: false,
    })

    if (afterSubmit) {
      afterSubmit()
    }
  }, [value, extension, valid])

  const onKeyPress = (event: KeyboardEvent) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      onSubmit()
    }
  }

  return (
    <SubmitInputMol
      isFancy={isFancy}
      buttonText={submitText}
      buttonTitle={submitText}
      buttonMobileIcon={Search}
      onKeyPress={onKeyPress}
      value={value}
      placeholder={componentText}
      onChange={onChange}
      onSubmit={onSubmit}
      hasError={!valid}
      errorMessage={!valid ? infoText : undefined}
      data-test-id="domain-search-box"
      hasAutofocus={autoFocus}
      icon={icon}
      hasHiddenBorder={hasHiddenBorder}
      hasWiderButton={hasWiderButton}
      shadow={shadow}
      className="t-input"
      name="domainsearch"
      buttonClassName="t-click"
      buttonDataTName="search"
      backgroundColor={backgroundColor}
    />
  )
}
