import type { ReactElement, ReactNode } from "react"
import React, { PureComponent, useRef } from "react"
import _t from "@core/i18n"
import ThinX from "@onestore-icons/thin-x.svg"
import Tick from "@onestore-icons/tick.svg"
import { get } from "lodash"
import ButtonAtm from "@onestore/hel/dist/components/atoms/ButtonAtm"
import IconAtm from "@onestore/hel/dist/components/atoms/IconAtm"
import PlainLinkAtm from "@onestore/hel/dist/components/atoms/PlainLinkAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import SimplePageHeaderMol from "@onestore/hel/dist/components/molecules/SimplePageHeaderMol"
import AlignContentOrg from "@onestore/hel/dist/components/organisms/AlignContentOrg"
import ContainerOrg from "@onestore/hel/dist/components/organisms/ContainerOrg"
import GridOrg from "@onestore/hel/dist/components/organisms/GridOrg"
import PushOrg from "@onestore/hel/dist/components/organisms/PushOrg"
import SectionOrg from "@onestore/hel/dist/components/organisms/SectionOrg"
import SurfaceOrg from "@onestore/hel/dist/components/organisms/SurfaceOrg"
import WrapOrg from "@onestore/hel/dist/components/organisms/WrapOrg"
import { pushErrorMessage } from "@onestore/onestore-store-common/debug"
import { useConnectionCheck } from "@gatsby-plugin-domain-search/sections/ErrorSection/hooks"
import useMenuQuery from "@gatsby-plugin-onestore/hooks/useMenuQuery"
import useSiteMetadataQuery from "~/hooks/useSiteMetadataQuery"

interface ErrorBoundaryProps {
  children: ReactNode
}

interface ErrorBoundaryState {
  hasError: boolean
}

const imgSrc = "/assets/img/connection-test.png"

function ErrorLayout(): ReactElement {
  const { ogSiteName, helpContactUrl } = useSiteMetadataQuery()
  const { menu } = useMenuQuery()
  const headerRef = useRef(null)
  const websiteConnection = useConnectionCheck(imgSrc)

  const onRetryClick = (): void => {
    window.location.reload()
  }

  return (
    <main>
      <SimplePageHeaderMol
        logo={{
          to: "/",
          title: ogSiteName,
          src: get(menu, "flatData.logo[0].url"),
          alt: `Logo ${ogSiteName}`,
        }}
        ref={headerRef}
      />

      <SectionOrg>
        <ContainerOrg>
          <SurfaceOrg spacingSpace={4} color="backgroundLight" shadow="small">
            <AlignContentOrg
              horizontalAlign="center"
              horizontalAlignType="flex"
            >
              <TextAtm typography="large2" pushSpace={2}>
                {_t("domainSearch.searchResultsLoaderTitleFail")}
              </TextAtm>

              <PushOrg bottomSpace={4}>
                <GridOrg columns={1} gutterSpace={1}>
                  <WrapOrg gutterSpace={2}>
                    {window.navigator.onLine ? (
                      <IconAtm icon={Tick} sizeSpace={2} color="success" />
                    ) : (
                      <IconAtm icon={ThinX} sizeSpace={2} color="error" />
                    )}

                    <TextAtm typography="small2">
                      {_t("domainSearch.networkOnline")}
                    </TextAtm>
                  </WrapOrg>

                  <WrapOrg gutterSpace={2}>
                    {websiteConnection ? (
                      <IconAtm icon={Tick} sizeSpace={2} color="success" />
                    ) : (
                      <IconAtm icon={ThinX} sizeSpace={2} color="error" />
                    )}

                    <TextAtm typography="small2">
                      {_t("domainSearch.websiteOnline")}
                    </TextAtm>
                  </WrapOrg>
                </GridOrg>
              </PushOrg>

              <ButtonAtm
                onClick={onRetryClick}
                title={_t("error.refreshPage")}
                color="dark"
              >
                {_t("error.refreshPage")}
              </ButtonAtm>

              <PushOrg bottomSpace={2} topSpace={4}>
                <TextAtm typography="tiny1">
                  {_t("error.contactUsMessage")}
                  {" - "}
                  <PlainLinkAtm
                    typography="tiny1"
                    type="underline"
                    openInNewTab
                    color="secondary"
                    href={helpContactUrl}
                    title={_t("error.contactUsLink")}
                  >
                    {_t("error.contactUsLink")}
                  </PlainLinkAtm>
                </TextAtm>
              </PushOrg>
            </AlignContentOrg>
          </SurfaceOrg>
        </ContainerOrg>
      </SectionOrg>
    </main>
  )
}

export default class ErrorBoundary extends PureComponent<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props)
    this.state = { hasError: false }
  }

  static componentDidCatch(error, errorInfo) {
    pushErrorMessage(error)
  }

  static getDerivedStateFromError(error) {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorLayout />
    }

    return this.state.hasError ? null : this.props.children
  }
}
