import type { ReactElement } from "react"
import React from "react"
import get from "lodash/get"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { getDomainsPoolId } from "@gatsby-plugin-generic-page/helpers/getDomainsPoolId"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import useCurrentPlanSettings from "@gatsby-plugin-marketplace/hooks/useCurrentPlanSettings"
import findProductOptions from "~/components/ParametersTable/findProductOptions"
import { AddToBasketButton } from "~/components/ProductParameters/AddToBasketButton"
import { Button } from "~/components/ProductParameters/Button"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import isEmpty from "~/lib/isEmpty"

type ProductButtonProps = {
  saleConfiguration: SaleConfiguration
  name: string
}

export function ProductButton({
  saleConfiguration,
  name,
}: ProductButtonProps): ReactElement | null {
  const currentPlanSettings = useCurrentPlanSettings(saleConfiguration)

  const productOptions = findProductOptions(
    currentPlanSettings,
    saleConfiguration
  )

  const productOptionsPeriod = productOptions?.period?.period

  const productOptionsPlanData: CloudBluePlan["flatData"] | undefined = get(
    productOptions,
    "cloudBluePlan[0].flatData"
  )

  const defaultCloudBluePlanData: CloudBluePlan["flatData"] | undefined = get(
    saleConfiguration,
    "flatData.defaultCloudBluePlan[0].flatData"
  )

  const productData = productOptionsPlanData || defaultCloudBluePlanData

  if (saleConfiguration.flatData.skipBonusScreen) {
    return (
      <AddToBasketButton
        product={productData || {}}
        name={name}
        saleConfigurationId={saleConfiguration.id}
        isDisabled={isEmpty(productData)}
      />
    )
  }

  return (
    <Button
      name={name}
      bonus={{
        p: productData?.alias,
        ax: getUpsellData(saleConfiguration.flatData.upsell),
        period: productOptionsPeriod,
        domainSearch: getDomainsPoolId(saleConfiguration),
      }}
      isDisabled={isEmpty(productData)}
    />
  )
}
