/* eslint-disable no-param-reassign */
/**
 * @module DomainSearch
 * @category State
 */
import type { Draft } from "immer"
import { produce } from "immer"
import { set } from "lodash"
import type { DomainCheck } from "@onestore/api/domainSearch"
import type { HTTP } from "@onestore/onestore-store-common"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import {
  DOMAIN_SEARCH_ACTIONS,
  DOMAIN_SEARCH_VIEW,
  DOMAIN_STATUS,
} from "@gatsby-plugin-domain-search/store/constants"
import type { DomainSearchActions } from "./actions"
import { ACTIONS } from "./actions"

export interface DomainSearchState {
  readonly rawPhrase: DomainCheck.RawPhrase
  readonly currentCheck: {
    available: boolean | undefined // dostępność domeny wpisanej przez uytkownika
    request_state: HTTP.Status // aktualny status wyszukiwarki
    phrase: DomainCheck.Phrase
    bundles: DomainCheck.DomainSearchBundle[]
    domain: DomainCheck.Result | null // główna domena, wyszukiwana na podstawie frazy
    alternative: DomainCheck.Result | null // domena alternatywna pojawia sie w przypadku zajętości domeny głownej
    premium: DomainCheck.ExternalSearchResult | null // domena oferowana w premium.pl pojawia sie w przypadku zajętości domeny głownej i dostepnosci w premium
    special: DomainCheck.Result | null // domena specjalnie dla ciebie
    poolId: DomainCheck.PoolId | null // id puli domen, jesli szukamy danje frazy przez przeklik(parametr w hashu urla)
    extension: DomainCheck.Extension | null // końcówka, jesli szukamy przez przeklik(parametr w hashu urla)
    unavailable_extensions: DomainCheck.Extension[]
  }
  readonly allDomainsCheck: Record<DomainCheck.Extension, DomainCheck.Result>
  readonly queue: DomainCheck.FQDN[]
  readonly poolCheck: {
    request_state: HTTP.Status
    pool: number | null
    domains: DomainCheck.Result[]
  }
  readonly suggestedCheck: {
    request_state: HTTP.Status
    domains: DomainCheck.Result[]
  }
  readonly pageType: DOMAIN_SEARCH_VIEW
  readonly validation: {
    valid: boolean
    errors: string[]
  }
  readonly searchModal: {
    visible: boolean
    extension: DomainCheck.Extension | undefined
    title: string
  }
  readonly modal: {
    changePeriod: {
      isOpen: boolean
      extension: string | null
      itemId: number | null
      period: CloudBluePeriod | undefined
    }
  }
}

export const initialState: DomainSearchState = {
  rawPhrase: "",
  currentCheck: {
    available: undefined, // dostępność domeny wpisanej przez uytkownika
    request_state: HTTP_STATUS.NONE, // aktualny status wyszukiwarki
    phrase: "", // wpisana przez użytkownika fraza
    domain: null, // główna domena, wyszukiwana na podstawie frazy
    alternative: null, // domena alternatywna pojawia sie w przypadku zajętości domeny głownej i dostępności jej w premium
    premium: null, // domena alternatywna pojawia sie w przypadku zajętości domeny głownej
    bundles: [], // zawartość bundle domen do zakupu
    special: null, // domena specjalnie dla ciebie
    poolId: null, // id puli domen, jesli szukamy danje frazy przez przeklik(parametr w hashu urla)
    extension: null, // końcówka, jesli szukamy przez przeklik(parametr w hashu urla)
    unavailable_extensions: [],
  },
  allDomainsCheck: {},
  queue: [],
  poolCheck: {
    request_state: HTTP_STATUS.NONE,
    pool: null,
    domains: [],
  },
  suggestedCheck: {
    request_state: HTTP_STATUS.NONE,
    domains: [],
  },
  pageType: DOMAIN_SEARCH_VIEW.PAGE_TYPE_POOL,
  validation: {
    valid: true,
    errors: [],
  },
  searchModal: {
    visible: false,
    extension: undefined,
    title: "",
  },
  modal: {
    changePeriod: {
      isOpen: false,
      extension: null,
      itemId: null,
      period: undefined,
    },
  },
}

export const getCurrentStateBasedOnAction = (action: string): number => {
  switch (action) {
    case DOMAIN_SEARCH_ACTIONS.CHECK_PENDING:
      return HTTP_STATUS.CONTINUE
    case DOMAIN_SEARCH_ACTIONS.CHECK_SUCCESS:
      return HTTP_STATUS.OK
  }

  return HTTP_STATUS.BAD_REQUEST
}

/**
 * Domain search reducer function, it handles all domain search state logic.
 */
export default function reducer(
  state: DomainSearchState = initialState,
  action: DomainSearchActions
): DomainSearchState {
  return produce(state, (nextState: Draft<DomainSearchState>): void => {
    switch (action.type) {
      case ACTIONS.SEARCH_MODAL_HIDE:
        nextState.searchModal = {
          visible: false,
          title: "",
          extension: undefined,
        }
        break

      case ACTIONS.SEARCH_MODAL_SHOW:
        nextState.searchModal = {
          visible: true,
          title: action.title,
          extension: action.extension,
        }

        break
      // Views

      // Search - Main
      case DOMAIN_SEARCH_ACTIONS.CHECK_PENDING:
        nextState.pageType = DOMAIN_SEARCH_VIEW.PAGE_TYPE_POOL
        set(nextState, ["currentCheck", "phrase"], action.phrase)
        set(
          nextState,
          ["currentCheck", "request_state"],
          getCurrentStateBasedOnAction(action.type)
        )
        set(nextState, ["currentCheck", "premium"], null)
        set(nextState, ["currentCheck", "bundles"], [])
        set(nextState, ["currentCheck", "alternative"], null)
        set(nextState, ["currentCheck", "available"], undefined)
        set(nextState, ["currentCheck", "domain"], null)
        set(nextState, ["currentCheck", "special"], null)
        set(nextState, ["currentCheck", "valid"], undefined)
        set(nextState, ["currentCheck", "poolId"], action.poolId)
        set(nextState, ["currentCheck", "extension"], action.extension)

        break

      case DOMAIN_SEARCH_ACTIONS.CHECK_FAILURE:
        if (action.phrase !== state.currentCheck.phrase) {
          return
        }

        set(
          nextState,
          ["currentCheck", "request_state"],
          getCurrentStateBasedOnAction(action.type)
        )
        set(nextState, ["currentCheck", "phrase"], action.phrase)
        set(nextState, ["currentCheck", "poolId"], action.poolId)
        set(nextState, ["currentCheck", "extension"], action.extension)

        break

      case DOMAIN_SEARCH_ACTIONS.CHECK_SUCCESS:
        if (action.phrase !== state.currentCheck.phrase) {
          return
        }

        set(
          nextState,
          ["currentCheck", "request_state"],
          getCurrentStateBasedOnAction(action.type)
        )
        set(nextState, ["currentCheck", "domain"], action.result.domain)
        set(
          nextState,
          ["currentCheck", "unavailable_extensions"],
          action.result.unavailable_extensions
        )
        set(nextState, ["currentCheck", "premium"], action.result.premium)
        set(nextState, ["currentCheck", "bundles"], action.result.bundles)
        set(
          nextState,
          ["currentCheck", "alternative"],
          action.result.alternative
        )
        set(
          nextState,
          ["currentCheck", "available"],
          action.result.domain.status === DOMAIN_STATUS.AVAILABLE
        )
        set(nextState, ["currentCheck", "special"], action.result.special)
        set(nextState, ["currentCheck", "valid"], action.result.valid)
        set(nextState, ["currentCheck", "poolId"], action.poolId)
        set(nextState, ["currentCheck", "extension"], action.extension)
        set(nextState, ["currentCheck", "phrase"], action.result.phrase)

        return
      // Search - Pool
      case DOMAIN_SEARCH_ACTIONS.POOL_PENDING:
        set(nextState, ["poolCheck", "request_state"], HTTP_STATUS.CONTINUE)
        set(nextState, ["poolCheck", "pool"], action.poolId)
        nextState.pageType = DOMAIN_SEARCH_VIEW.PAGE_TYPE_POOL
        nextState.allDomainsCheck = {}

        return

      case DOMAIN_SEARCH_ACTIONS.POOL_FAILURE:
        set(nextState, ["poolCheck", "request_state"], HTTP_STATUS.BAD_REQUEST)

        return

      case DOMAIN_SEARCH_ACTIONS.POOL_SUCCESS:
        set(nextState, ["poolCheck", "domains"], action.result)
        set(nextState, ["poolCheck", "request_state"], HTTP_STATUS.OK)

        return

      case DOMAIN_SEARCH_ACTIONS.SUGGESTED_FAILURE:
        set(
          nextState,
          ["suggestedCheck", "request_state"],
          HTTP_STATUS.BAD_REQUEST
        )

        return

      case DOMAIN_SEARCH_ACTIONS.SUGGESTED_PENDING:
        set(
          nextState,
          ["suggestedCheck", "request_state"],
          HTTP_STATUS.CONTINUE
        )

        return

      case DOMAIN_SEARCH_ACTIONS.SUGGESTED_SUCCESS:
        set(nextState, ["suggestedCheck", "domains"], action.result)
        set(nextState, ["suggestedCheck", "request_state"], HTTP_STATUS.OK)

        return

      // Lazyloading
      case DOMAIN_SEARCH_ACTIONS.LAZY_ENQUEUE:
        // Dodaj do kolejki wyszukiwania.
        // Jeżeli już jest w kolejce lub mamy już dane dla tej domeny, to nic nie rób.
        if (state.queue.includes(action.domain)) {
          return
        }

        nextState.queue.push(action.domain)

        return

      case DOMAIN_SEARCH_ACTIONS.LAZY_DEQUEUE:
        // Usuń z kolejki wyszukiwania.
        // Jeżeli nie ma w kolejce, to nic nie rób.
        if (!state.queue.includes(action.domain)) {
          return
        }

        nextState.queue = state.queue.filter((item) => item !== action.domain)

        return

      case DOMAIN_SEARCH_ACTIONS.LAZY_PENDING:
        // Wysłano zapytanie o dane z kolejki.
        // Dla każdej domeny którą chcemy sprawdzić...
        action.domains.forEach((domain) => {
          // ...jeżeli jeszcze nie mamy wysłanego zapytania o tę domenę...
          // ...i zdejmujemy ją z kolejki do wyszukiwania.
          nextState.queue = state.queue.filter((item) => item !== domain)
        })
        break

      case DOMAIN_SEARCH_ACTIONS.LAZY_FAILURE:
        // Zapytanie o domeny nie powiodło się.
        // TODO: trzeba to wyświetlić klientowi.
        break

      case DOMAIN_SEARCH_ACTIONS.LAZY_SUCCESS:
        // Zapytanie o domeny powiodło się.
        // Zwracamy nowy stan, powiększony o rezultaty wyszukiwania.

        action.result.forEach((domain) => {
          nextState.allDomainsCheck[domain.fqdn] = domain
        })

        break

      case DOMAIN_SEARCH_ACTIONS.VALIDATION_SUCCESS:
        nextState.validation = {
          valid: true,
          errors: [],
        }
        nextState.rawPhrase = action.rawPhrase

        break

      case DOMAIN_SEARCH_ACTIONS.VALIDATION_CLEAR:
        nextState.validation = {
          valid: true,
          errors: [],
        }

        break
      case DOMAIN_SEARCH_ACTIONS.VALIDATION_FAILURE:
        nextState.rawPhrase = action.rawPhrase

        set(nextState, ["currentCheck", "phrase"], action.phrase)
        set(nextState, ["validation", "valid"], false)
        set(nextState, ["validation", "errors"], action.errors)

        break

      case DOMAIN_SEARCH_ACTIONS.TOGGLE_ADVANCED_MODE:
        nextState.pageType = DOMAIN_SEARCH_VIEW.PAGE_TYPE_FULL
        break

      case ACTIONS.CHANGE_ITEM_PERIOD_MODAL_SHOW:
        nextState.modal.changePeriod.isOpen = true
        nextState.modal.changePeriod.extension = action.extension
        nextState.modal.changePeriod.itemId = action.itemId
        nextState.modal.changePeriod.period = action.period

        return

      case ACTIONS.CHANGE_ITEM_PERIOD_MODAL_CLOSE:
        nextState.modal.changePeriod.isOpen = false
        nextState.modal.changePeriod.extension = null
        nextState.modal.changePeriod.itemId = null

        return
    }
  })
}
