import type { ReactElement, ReactNode } from "react"
import React from "react"
import type { DomainCheck } from "@onestore/api/domainSearch"
import LabelAtm from "@onestore/hel/dist/components/atoms/LabelAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import DomainSearchResultBoxOrg from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg"
import type { AdditionalInfoProps } from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg/AdditionalInfo"
import type { SubItemProps } from "@onestore/hel/dist/components/organisms/DomainSearchResultBoxOrg/SubItem"
import { Aside } from "@gatsby-plugin-domain-search/components/MainResultBox/Aside"
import { DomainName } from "@gatsby-plugin-domain-search/components/MainResultBox/DomainName"
import { Footer } from "@gatsby-plugin-domain-search/components/MainResultBox/Footer"
import { MainResultImage } from "@gatsby-plugin-domain-search/components/MainResultBox/MainResultImage"
import { getDomainPromoLabel } from "@gatsby-plugin-domain-search/lib/promo"
import type { CtaSectionsModal } from "@gatsby-plugin-generic-page/fragments/ctaSectionsModal"
import { md } from "~/lib/i18n"
import { TextAtmBold, TextAtmBoldError } from "~/lib/markdown"
import { COMPONENT_ID } from "~/types/component"

interface AlternativeMainResultBoxProps {
  subItems: SubItemProps[] | undefined
  domain: DomainCheck.Result
  sortingTooltipContent?: string | null
  priceToggle?: ReactNode
  ctaSectionsModal?: CtaSectionsModal
  additionalInfo?: AdditionalInfoProps
}

export default function AlternativeMainResultBox({
  subItems,
  domain,
  priceToggle,
  ctaSectionsModal,
  sortingTooltipContent,
  additionalInfo,
}: AlternativeMainResultBoxProps): ReactElement<AlternativeMainResultBoxProps> | null {
  const availableText = md(
    "domainSearch.mainDomainHeaderTextRecommended",
    undefined,
    {
      strong: TextAtmBold,
    }
  )

  const promoLabel = getDomainPromoLabel(domain.extension)

  return (
    <div id={COMPONENT_ID.DOMAIN_SEARCH_MAIN_RESULT}>
      <DomainSearchResultBoxOrg
        header={
          <LabelAtm
            size="large"
            variant={
              domain.status === "Available" ? "successLight" : "errorLight"
            }
            emphasis="high"
            borderRadius="mlarge"
          >
            <TextAtm typography="small1">
              {domain.status === "Available"
                ? availableText
                : md(
                    "domainSearch.mainDomainHeaderTextNotAvailable",
                    undefined,
                    {
                      strong: TextAtmBoldError,
                    }
                  )}
            </TextAtm>
          </LabelAtm>
        }
        image={
          <MainResultImage
            isPhraseIdenticalToFqdn={false}
            available={domain.status === "Available"}
          />
        }
        main={<DomainName label={promoLabel} domain={domain} />}
        aside={<Aside mainDomain={domain} />}
        footer={
          <Footer
            ctaSectionsModal={ctaSectionsModal}
            sortingTooltipContent={sortingTooltipContent}
          />
        }
        subItems={subItems}
        priceToggle={priceToggle}
        additionalInfo={additionalInfo}
      />
    </div>
  )
}
