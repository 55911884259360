import type { ReactElement } from "react"
import React from "react"
import get from "lodash/get"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { getDomainsPoolId } from "@gatsby-plugin-generic-page/helpers/getDomainsPoolId"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import { AddToBasketButton } from "~/components/ProductParameters/AddToBasketButton"
import { Button } from "~/components/ProductParameters/Button"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import useProductSettings from "~/hooks/useProductSettings"
import isEmpty from "~/lib/isEmpty"

type CurrentProductButtonProps = {
  saleConfiguration: SaleConfiguration
  name: string
}

export function CurrentProductButton({
  saleConfiguration,
  name,
}: CurrentProductButtonProps): ReactElement | null {
  const productOptions = useProductSettings(saleConfiguration)

  const productOptionsPlanData: CloudBluePlan["flatData"] | undefined = get(
    productOptions,
    "cloudBluePlan[0].flatData"
  )

  const defaultPlanData: CloudBluePlan["flatData"] | undefined = get(
    saleConfiguration,
    "flatData.defaultCloudBluePlan[0].flatData"
  )

  const product: CloudBluePlan["flatData"] | undefined =
    productOptionsPlanData || defaultPlanData

  if (isEmpty(product)) {
    return null
  }

  if (saleConfiguration.flatData.skipBonusScreen) {
    return (
      <AddToBasketButton
        product={product}
        name={name}
        saleConfigurationId={saleConfiguration.id}
      />
    )
  }

  return (
    <Button
      name={name}
      bonus={{
        p: product.alias,
        ax: getUpsellData(saleConfiguration.flatData.upsell),
        period: !isEmpty(productOptions)
          ? productOptions.period?.period
          : undefined,
        domainSearch: getDomainsPoolId(saleConfiguration),
      }}
    />
  )
}
