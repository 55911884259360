export enum COMPONENT_ID {
  PRODUCT_HERO = "productHero",
  PRODUCT_BUNDLE = "productBundle",
  COST_CALCULATOR = "costCalculator",
  MARKETPLACE_COMPARE = "marketplaceCompare",
  DOMAIN_SEARCH_SECTION_SECONDARY = "domain-search-secondary-results",
  DOMAIN_SEARCH_MAIN_RESULT = "domain-search-transfer",
  TERMS_SECTION = "suummary-terms-section",
  DOMAIN_SEARCH_PRODUCT_RESULTS = "domain-search-product-results",
  MESSAGE_BOX = "domain-search-message-box",
  AI_DOMAIN_RESULTS = "domain-search-ai-results",
}
