import { useStaticQuery, graphql } from "gatsby"

interface Query {
  GraphCmsSquidex: {
    findDomainSearchAiPageSingleton: {
      flatData: {
        footerModalContent?: string
      }
    }
  }
}

type Hook = {
  footerModalContent: string | null
}

export default function useDomainSearchAiFooterModalContentQuery(): Hook {
  const {
    GraphCmsSquidex: { findDomainSearchAiPageSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findDomainSearchAiPageSingleton {
          flatData {
            footerModalContent
          }
        }
      }
    }
  `)

  const footerModalContent =
    findDomainSearchAiPageSingleton.flatData.footerModalContent || null

  return {
    footerModalContent,
  }
}
