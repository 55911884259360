import type { DomainCheck } from "@onestore/api/domainSearch"
import type { PlanId } from "@onestore/api/types"
import type { HtmlTag } from "@onestore/hel/dist/components/atoms/TextAtm/TextAtm.types"
import type {
  Color,
  SpacingBase,
} from "@onestore/hel/dist/components/organisms/SectionOrg/SectionOrg.types"
import type Theme from "@onestore/hel/dist/typings/theme"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import type { BundleButtonWithPrice } from "@gatsby-plugin-generic-page/fragments/bundleButtonWithPrice"
import type { Counter } from "@gatsby-plugin-generic-page/fragments/counter"
import type { CtaElement } from "@gatsby-plugin-generic-page/fragments/ctaElement"
import type { Formily } from "@gatsby-plugin-generic-page/fragments/formily"
import type { FormilyWithThankYou } from "@gatsby-plugin-generic-page/fragments/formilyWithThankYou"
import type { Label } from "@gatsby-plugin-generic-page/fragments/label"
import type {
  Tooltip,
  TooltipFlatData,
} from "@gatsby-plugin-generic-page/fragments/tooltip"
import type { Video } from "@gatsby-plugin-generic-page/fragments/video"
import type { ProductSchema } from "@gatsby-plugin-marketplace/fragments/marketplaceProduct"
import type { ComparableProduct } from "~/fragments/comparableProduct"
import type { Faq } from "~/fragments/faq"
import type { Form } from "~/fragments/form"
import type { EmbeddedAssetSquidexMetadata } from "~/fragments/image"
import type { StaticPrice } from "~/fragments/staticPrice"
import type { Metadata } from "~/types/Metadata"
import type {
  Hero,
  PageWithExternalScripts,
} from "../../lib/onestore-graphql/Model"
import type { GenericSection } from "../../lib/onestore-graphql/Sections"

export interface GenericPage {
  id: string
  __typename: string
  flatData: {
    url: string
    canonical: string
    sitemapPriority: string
    redirect?: boolean
    hasDarkHeader?: boolean
    hasHeaderHideBoxShadow?: boolean
    sections: GenericSection[]
  } & Metadata["flatData"] &
    PageWithExternalScripts["flatData"]
}

export interface FlatData<T> {
  flatData: T
}

export type Pages = Page[] | GenericPage[] | []

export enum PageStatus {
  PUBLISHED = "PUBLISHED",
  DRAFT = "DRAFT",
  ARCHIVED = "ARCHIVED",
}

export interface Page {
  id: string
  status?: PageStatus
  flatData: {
    url: string
    canonical?: string
    sitemapPriority?: string
    noIndex?: boolean
    heroes: Hero[]
    publishDate?: string
    redirect?: boolean
    productSchemas?: ProductSchema[]
    faqs?: Faq[]
  } & Metadata["flatData"]
}

export type BackgroundImagePosition = "left" | "center" | "right" | "top center"

export interface BackgroundImage {
  images: ResponsiveImages
  position?: BackgroundImagePosition
  isCover?: boolean
}

export interface Spacing {
  top: SpacingBase
  bottom: SpacingBase
}

export interface ResponsiveImages {
  large: EmbeddedAssetSquidexMetadata[]
  small?: EmbeddedAssetSquidexMetadata[]
  medium?: EmbeddedAssetSquidexMetadata[]
}

export interface CloudBluePlanAxFlatData {
  legacyAxContent: string
  mainProductTitle: string
  mainProductContent: string
  mainProductAdditionalContent: string
  upsellProductTitle: string
  upsellProductContent: string
  upsellProductAdditionalContent: string
  pages?: Pages
}

export interface CloudBlueDomain {
  __typename: string
  id: string
  flatData: {
    id: PlanId
    promoted?: boolean
    promoLabel?: string | null
    extension: DomainCheck.Extension
    periods: CloudBluePeriod[]
    promotionPriceTooltip?: Tooltip[] | null
  }
}

export interface CloudBluePlanWithAx {
  __typename: string
  id: string
  flatData: {
    id: number
    alias: string
    periods: CloudBluePeriod[]
  } & CloudBluePlanAxFlatData
}

export type PeriodName =
  | "0d"
  | "14d"
  | "1m"
  | "2m"
  | "3m"
  | "6m"
  | "1y"
  | "2y"
  | "3y"
  | "4y"
  | "5y"
  | "6y"
  | "7y"
  | "8y"
  | "9y"
  | "10y"

export interface DomainExtensionLogo {
  id: string
  url: string
  fileName: string
}

export interface DomainExtension {
  id: string
  flatData: {
    id: number
    title: string
    description: string
    tooltip?: string
    label?: Label
    pages: {
      flatData: {
        url: string
      }
    }[]
    promotionPriceTooltip?: TooltipFlatData
    extension: string
    periods: CloudBluePeriod[]
    logo?: DomainExtensionLogo[]
  }
}

export interface DomainExtensionCategory {
  id: string
  flatData: {
    name: string
    domains: DomainExtension[]
    slug?: string
  }
}

export interface CaptionIcon {
  __typename: string
  captionIcon: EmbeddedAssetSquidexMetadata[]
}

export interface Caption {
  __typename: string
  text: string
}

export interface Indicator {
  title: string
  value: number
}

export interface PagesUrl {
  id: string
  flatData: {
    url: string
  }
}

export type TitleHtmlTag = Extract<HtmlTag, "h1" | "h2">

export interface FormButton {
  title: string
  text: string
  buttonColor: {
    color: Theme.AccentColor
  }
}

export interface FormSalesManago {
  __typename: string
  id: string
  flatData: {
    link: string
    id: string
  }
}

export type Forms = Form | FormSalesManago | Formily | FormilyWithThankYou

export interface Paragraph {
  text: string
  caption?: string
  title?: string
  backgroundColor?: Color
}

export interface List {
  icon: EmbeddedAssetSquidexMetadata[]
  text: string
}

export type SquidexPriceType =
  | CloudBluePlan[]
  | StaticPrice[]
  | BundleButtonWithPrice[]
  | CloudBlueDomain[]

export interface TextWithPrice {
  text: string
  price: SquidexPriceType
}

export interface TableCell {
  text: string
  align: "left" | "right" | "center"
}

export interface TableRow {
  icon: EmbeddedAssetSquidexMetadata[]
  cells: TableCell[]
}

export interface StaticTable {
  flatData: {
    rows: TableRow[]
  }
}

export interface ExternalLink {
  __typename: string
  href: string
  openInNewTab?: boolean
}

export interface AnchorId {
  __typename: string
  anchorId: string
}

export interface Post {
  icon: EmbeddedAssetSquidexMetadata[]
  iconText: string
  title: string
  texts: {
    text: string
  }[]
  ctaElements?: CtaElement[]
}

export interface ComparableProductsPackage {
  id: string
  flatData: {
    name: string
    externalProducts: ComparableProduct[]
  }
}

export enum CustomTextColors {
  jasny = "customDark",
  ciemny = "customLight",
}

export type BackgroundColor = { __typename: string; color: string }

export type Gradient = {
  __typename: string
  start: string
  end: string
}

export type CustomBackgroundColor = BackgroundColor | Gradient

export type SquidexMarkdownContents = CtaElement | Video | Counter

export interface SquidexMarkdownEmbeddedContent {
  text: string
  assets: EmbeddedAssetSquidexMetadata[]
  contents: SquidexMarkdownContents[]
}
