import { useStaticQuery, graphql } from "gatsby"
import type { DomainBundle } from "@gatsby-plugin-domain-search/fragments/domainBundle"
import isEmpty from "~/lib/isEmpty"
import type { DomainBundleHook } from "./useMainDomainBundleQuery"

type Query = {
  GraphCmsSquidex: {
    findDomainSearchPageSingleton: {
      id: string
      flatData: {
        domainAXBundle?: DomainBundle
      }
    }
  }
}

export default function useAXDomainBundleQuery(): DomainBundleHook {
  const {
    GraphCmsSquidex: { findDomainSearchPageSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findDomainSearchPageSingleton {
          id
          flatData {
            domainAXBundle {
              ...DomainBundle
            }
          }
        }
      }
    }
  `)

  if (
    isEmpty(findDomainSearchPageSingleton) ||
    !findDomainSearchPageSingleton.flatData.domainAXBundle
  ) {
    return {
      id: 0,
      title: null,
      extensions: [],
      additionalBoxText: undefined,
      additionalBoxIcon: undefined,
    }
  }

  const { text, extensions, additionalBoxText, additionalBoxIcon } =
    findDomainSearchPageSingleton.flatData.domainAXBundle

  return {
    id: 0, // squidex components don't have their own id, so we use 0 because only one item is used
    title: text,
    extensions: extensions.map((item): string => item.flatData.extension),
    additionalBoxText,
    additionalBoxIcon,
  }
}
