import type { ReactElement, ReactNode } from "react"
import React from "react"
import _t from "@core/i18n"
import AiIcon from "@onestore-icons/ai.svg"
import SearchIcon from "@onestore-icons/search.svg"
import IconAtm from "@onestore/hel/dist/components/atoms/IconAtm"
import TextAtm from "@onestore/hel/dist/components/atoms/TextAtm"
import DomainSearchTabsMol, {
  DomainSearchTabsMolProps,
} from "@onestore/hel/dist/components/molecules/DomainSearchTabsMol"
import Theme from "@onestore/hel/dist/typings/theme"
import useDomainSearchAiSettingsQuery from "@gatsby-plugin-domain-search/hooks/useDomainSearchAiSettingsQuery"

type DomainSearchInputTabsProps = {
  activeTabIndex: number
  domainSearchInput: ReactNode
  domainSearchPrompt: ReactNode
  showOnTop?: boolean
  activeBackgroundColor?: Theme.BackgroundColor
  backgroundColor?: Theme.BackgroundColor
}

function DomainSearchInputTabs({
  activeTabIndex,
  domainSearchInput,
  domainSearchPrompt,
  showOnTop = false,
  activeBackgroundColor = "backgroundLight",
  backgroundColor = "backgroundDarkLight",
}: DomainSearchInputTabsProps): ReactElement<DomainSearchInputTabsProps> {
  const { enabled } = useDomainSearchAiSettingsQuery()

  // if brand has AI search disabled show classic search input
  if (!enabled) {
    return <>{domainSearchInput}</>
  }
  const tabs: DomainSearchTabsMolProps["tabs"] = [
    {
      header: (
        <>
          <IconAtm icon={SearchIcon} sizeSpace={2} />

          <TextAtm typography="xsmall1" pushSpace={{ left: 0.5 }}>
            {_t("domainSearch.classicSearchTab")}
          </TextAtm>
        </>
      ),
      title: _t("domainSearch.classicSearchTab"),
      onClick: () => null,
      content: domainSearchInput,
      isInUpperSurface: false,
    },
    {
      header: (
        <>
          <IconAtm icon={AiIcon} sizeSpace={2} color="violet" />

          <TextAtm typography="xsmall1" pushSpace={{ left: 0.5 }}>
            {_t("domainSearch.aiSearchTab")}
          </TextAtm>
        </>
      ),
      title: _t("domainSearch.aiSearchTab"),
      onClick: () => null,
      content: domainSearchPrompt,
      isInUpperSurface: showOnTop,
    },
  ]

  return (
    <DomainSearchTabsMol
      activeBackgroundColor={activeBackgroundColor}
      backgroundColor={backgroundColor}
      activeTabIndex={activeTabIndex}
      minHeightSpace={15.5}
      tabs={tabs}
    />
  )
}

export default DomainSearchInputTabs
