import { PeriodInfo } from "@core/period-info"
import type { Draft } from "immer"
import { ProductType } from "@onestore/api/basket"
import type {
  PeriodName,
  Price,
  ProductAlias,
  Resource,
  ResourceId,
} from "@onestore/api/types"
import type {
  AliasProductData,
  DomainData,
} from "@onestore/onestore-store-common"
import { HTTP_STATUS } from "@onestore/onestore-store-common/http"
import { normalizeMaxQuantity } from "@gatsby-plugin-bonus/lib/normalizers"
import {
  getResourceLabels,
  updatePeriodPriceFromExternalSource,
} from "@gatsby-plugin-bonus/store/utils"
import type {
  BonusDefinitions,
  BonusPeriod,
  BonusProduct,
  BonusResource,
  BonusResources,
  InitialDomainBonusProduct,
} from "@gatsby-plugin-bonus/types"
import type { CloudBluePeriod } from "@gatsby-plugin-definitions/fragments/CloudBluePeriod"
import type { PeriodResourceRate } from "@gatsby-plugin-definitions/store/actions"
import { getProductPlanByAlias } from "~/lib/definitions"
import { sortPlanPeriods } from "~/lib/plan"
import parseQuery, { getParamsOfType } from "../query-parser"

export const SPECIAL_ALIASES = new Set(["DOMAINS", "PMAIL"])

function getBonusResourceSetup(parameters) {
  const result = {}

  Object.keys(parameters).forEach((key) => {
    if (key.indexOf("resource[") < 0) {
      return
    }
    const id = parseInt(key.replace(/[^0-9+]/gi, ""), 10)

    const basketQuantity = parseInt(parameters[key], 10)

    if (Number.isNaN(basketQuantity) || basketQuantity === null) {
      return
    }
    result[id] = {
      id,
      basketQuantity,
    }
  })

  return result
}

/**
 * Zwraca definicje produktów przedkoszyka wygenerowaną z urla.
 *
 * @param {string} query Parametry z urla
 * @returns {Object} Definicje produktów przedkoszyka.
 */
export default function getBonusDefinitions(query): BonusDefinitions | {} {
  const parsedData = parseQuery(query)

  if (null === parsedData) {
    return {}
  }

  const definitions: Draft<BonusDefinitions> = {
    calculation: {
      bundled: undefined,
      product: undefined,
    },
    definitionsLoaded: false,
    upsellDomains: {
      results: [],
      status: 0,
    },
    forms: {},
    special: parsedData.special,
    promoCode: parsedData.promoCode,
    chosenPeriodName: parsedData.chosenPeriod,
    hidePeriods: parsedData.hidePeriods,
    checkoutMode: parsedData.checkoutMode,
    domainSearch: parsedData.domainSearch,
    loaded: true,
    upsell: parsedData.upsell
      ? getUpsellDefinitions(parsedData.upsell, parsedData.allPeriod || null)
      : {},
  }

  if (parsedData.product) {
    const productMinQuantity =
      parsedData.product.parameters &&
      "quantity" in parsedData.product.parameters
        ? parseInt(parsedData.product.parameters.quantity, 10)
        : null

    const productPeriod =
      parsedData.allPeriod ?? (parsedData.chosenPeriod || null)

    const productDefinition = getDefinition(
      parsedData.product.alias,
      productPeriod,
      null,
      productMinQuantity
    )

    if (productDefinition) {
      definitions.product = productDefinition

      if (parsedData.product && parsedData.product.parameters) {
        definitions.product.resources = getBonusResourceSetup(
          parsedData.product.parameters
        )

        definitions.product.initialParamFormData = getParamsOfType(
          "param",
          parsedData.product.parameters
        )
      }
    }

    if (definitions.upsell) {
      delete definitions.upsell[parsedData.product.alias]
    }
  }

  if (parsedData.domain) {
    const domainDefinition = getDomainDefinition(parsedData.domain)

    if (domainDefinition) {
      definitions.domain = domainDefinition
    }
  }

  if (parsedData.bundled) {
    const bundledDefinition = getDefinition(
      parsedData.bundled.alias,
      parsedData.allPeriod || null,
      parsedData.product?.alias
    )

    if (bundledDefinition) {
      definitions.bundled = bundledDefinition

      if (definitions.upsell) {
        delete definitions.upsell[parsedData.bundled.alias]
      }
    }
  }

  return definitions
}

function getDefaultPeriod(
  periods: CloudBluePeriod[],
  quantityDivider: number
): BonusPeriod {
  const defaultPeriod = periods.find((period) => period.default)

  if (!defaultPeriod) {
    throw Error("Missing default period")
  }

  return getPeriod(defaultPeriod, quantityDivider)
}

function getPeriodByName(
  periods: CloudBluePeriod[],
  chosenPeriod: PeriodName | null,
  quantityDivider: number
): BonusPeriod {
  if (null === chosenPeriod) {
    return getDefaultPeriod(periods, quantityDivider)
  }

  const result = periods.find((period) => period.period_name === chosenPeriod)

  if (undefined === result) {
    return getDefaultPeriod(periods, quantityDivider)
  }

  return getPeriod(result, quantityDivider)
}

export function getDefinition(
  alias,
  chosenPeriod: string | null = null,
  parentProductAlias: string | null = null,
  externalMinQuantity: number | null = null // quantity from URL
): BonusProduct | null {
  try {
    const product = getProductPlanByAlias(alias)

    const finalMinQuantity = externalMinQuantity
      ? externalMinQuantity
      : product.min_quantity || 1

    const period = getPeriodByName(
      product.periods,
      chosenPeriod,
      finalMinQuantity
    )

    let periods: BonusPeriod[] = convertPeriodToBonusPeriods(
      product.periods,
      finalMinQuantity
    )

    if (parentProductAlias) {
      const parentProduct = getProductPlanByAlias(parentProductAlias)

      periods = periods.map((period) => {
        const price = period.parent_promotions
          ? period.parent_promotions.find(
              (item) => item.parent_plan_id === parentProduct.id
            ) ?? null
          : null

        return period.period_name === product.default_plan_period && price
          ? updatePeriodPriceFromExternalSource(
              period,
              price.price_register,
              parentProduct.min_quantity || 1
            )
          : period
      })
    }

    return {
      name: product.name,
      quantityResource: product.quantity_resource ?? null,
      alias,
      planId: product.id,
      advancedConfiguration: product.advanced_configuration === true,
      configurationSaved: false,
      definitionLoaded: false,
      resourceCategories: [],
      hasQuantity: product.has_quantity === true,
      minQuantity: finalMinQuantity,
      periods: periods,
      totalPrices: {
        regularPrice: null,
        promoPrice: null,
      },
      defaultPeriodName: product.default_plan_period,
      chosenPeriodId: period.id,
      previousPeriodId: period.id,
      basketItemState: undefined,
      addedToBasket: false,
      addToBasketStatus: HTTP_STATUS.NONE,
      addToBasketError: undefined,
      changePeriodStatus: HTTP_STATUS.NONE,
      changePeriodError: undefined,
      getPriceStatus: HTTP_STATUS.NONE,
      getPriceError: undefined,
      isPriceLoading: false,
      isAddToBasketLoading: false,
      isChangePeriodLoading: false,
      resources: getResources(product.resources || {}, period),
      isChangeResourceLoading: false,
      changeResourceStatus: HTTP_STATUS.NONE,
      type: product.product_type,
      isValid: true,
      errors: {},
      removeFromBasketStatus: 0,
      removedFromBasket: false,
      parameters: {},
      resourceLabels: getResourceLabels(product.resources || {}),
      autoAddedToBasket: false,
      initialParamFormData: {},
    }
  } catch (error) {
    console.log(error)

    return null
  }
}

// Bundle z domeną na Black Friday 2020
export function getDomainDefinition(
  domainData: DomainData
): InitialDomainBonusProduct | null {
  if (!domainData.extension) {
    return null
  }

  try {
    const alias = domainData.extension

    return {
      alias,
      name: domainData.extension,
      planId: null,
      advancedConfiguration: false,
      configurationSaved: false,
      hasQuantity: false,
      minQuantity: 1,
      definitionLoaded: false,
      resourceCategories: [],
      periods: [],
      totalPrices: {
        regularPrice: null,
        promoPrice: null,
      },
      defaultPeriodName: null,
      chosenPeriodId: null,
      previousPeriodId: null,
      basketItemState: undefined,
      addedToBasket: false,
      addToBasketStatus: HTTP_STATUS.NONE,
      addToBasketError: undefined,
      changePeriodStatus: HTTP_STATUS.NONE,
      changePeriodError: undefined,
      getPriceStatus: HTTP_STATUS.NONE,
      getPriceError: undefined,
      isPriceLoading: false,
      isAddToBasketLoading: false,
      isChangePeriodLoading: false,
      isChangeResourceLoading: false,
      changeResourceStatus: HTTP_STATUS.NONE,
      type: ProductType.DOMAIN,
      isValid: true,
      errors: {},
      removeFromBasketStatus: 0,
      removedFromBasket: false,
      parameters: {},
      pool: domainData.pool,
      resourceLabels: {},
      autoAddedToBasket: false,
    }
  } catch (error) {
    console.log(error)

    return null
  }
}

export interface ProductPrice {
  promoPrice: Price | null
  regularPrice: Price
  renewalPrice: Price | null
}

/**
 * @todo usunąć zduplikowane propsy
 */
function getPeriod(
  period: CloudBluePeriod,
  quantityDivider: number
): BonusPeriod {
  const periodInfo = new PeriodInfo(period)

  const regularPrice = periodInfo.getRegularPriceValue(false)
  const renewalPrice = periodInfo.getRenewalPriceValue(quantityDivider)
  const promoPrice = periodInfo.getPromoPriceValue(quantityDivider)

  return {
    ...period,
    trial: period.trial || false,
    price: {
      regularPrice,
      promoPrice,
      renewalPrice,
    },
    resource_data: period.resource_data || [],
  }
}

export function convertPeriodToBonusPeriods(
  periods: CloudBluePeriod[],
  quantityDivider: number
): BonusPeriod[] {
  const prices: BonusPeriod[] = []
  sortPlanPeriods(periods).forEach((period) => {
    prices.push(getPeriod(period, quantityDivider))
  })

  return prices
}

function getUpsellDefinitions(
  data: AliasProductData[],
  chosenPeriod: string | null
): Record<ProductAlias, BonusProduct> {
  const upsell = {}

  if (!data) {
    return upsell
  }

  data.forEach((item) => {
    if (SPECIAL_ALIASES.has(item.alias)) {
      upsell[item.alias] = item
    } else if (!upsell[item.alias]) {
      const parameters = item.parameters || {}
      const period = "period" in parameters ? parameters.period : chosenPeriod

      const definition = getDefinition(item.alias, period)

      if (definition) {
        upsell[item.alias] = definition
      }
    }
  })

  return upsell
}

export function getResource(
  id: ResourceId,
  rate: PeriodResourceRate | undefined
): BonusResource {
  if (!rate) {
    throw new Error(`Resource Rate is missing for id: ${id}`)
  }

  const includedValue = rate.included_value
  const lowerLimit = rate.lower_limit
  const upperLimit = normalizeMaxQuantity(rate.upper_limit)

  const minQuantity =
    includedValue > 0 ? includedValue : lowerLimit > 0 ? lowerLimit : 0

  return {
    id,
    basketItemId: undefined,
    maxQuantity: upperLimit,
    quantity: minQuantity,
    basketQuantity: minQuantity - includedValue,
    minQuantity,
    includedValue,
  }
}

function getResources(
  resources: Record<string, Resource> | Resource[],
  chosenPeriod: BonusPeriod
): BonusResources {
  const resourcesData: BonusResources = {}

  Object.values(resources).forEach((resource: Resource) => {
    resourcesData[resource.id] = getResource(
      resource.id,
      chosenPeriod.resource_data.find(
        (data) => data.resource_id === resource.id
      )
    )
  })

  return resourcesData
}
