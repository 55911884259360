import type { ReactElement, SyntheticEvent } from "react"
import { useState } from "react"
import React from "react"
import type { ButtonAtmProps } from "@onestore/hel/dist/components/atoms/ButtonAtm"
import PackageBoxSlimMol from "@onestore/hel/dist/components/molecules/PackageBoxSlimMol"
import useBreakpoint from "@onestore/hel/dist/hooks/useBreakpoint"
import useFastCheckoutAction from "@gatsby-plugin-basket/hooks/useFastCheckoutAction"
import type { CloudBluePlan } from "@gatsby-plugin-definitions/fragments/CloudBluePlan"
import { ChangePeriodModal } from "@gatsby-plugin-generic-page/components/ProductBoxSlim/ChangePeriodModal"
import type { ChangePeriodModal as ChangePeriodModalType } from "@gatsby-plugin-generic-page/fragments/changePeriodModal"
import { getUpsellData } from "@gatsby-plugin-generic-page/helpers/getUpsellData"
import type { ProductBoxBundle } from "~/fragments/bundle"
import type { SaleConfiguration } from "~/fragments/saleConfigurations"
import type { ProductBoxSlimHookProps } from "~/hooks/useProductBoxSlim"
import useProductBoxSlim from "~/hooks/useProductBoxSlim"
import isEmpty from "~/lib/isEmpty"
import { useAppSelector } from "~/store/hooks"
import type { AppState } from "~/store/reducer"

export type ProductBoxWithSaleConfigurationProps = {
  product: Omit<ProductBoxSlimHookProps, "plan">
  saleConfiguration: SaleConfiguration[]
  bundle?: ProductBoxBundle[]
  planInputLabel?: string
  changePeriodModal?: ChangePeriodModalType["flatData"]
  index?: number
  isTrialBox?: boolean
}

type ButtonProps = Omit<ButtonAtmProps, "size" | "isWider" | "isExpanded">

export default function ProductBoxWithSaleConfiguration({
  product,
  saleConfiguration,
  bundle,
  planInputLabel,
  changePeriodModal,
  index,
  isTrialBox,
}: ProductBoxWithSaleConfigurationProps): ReactElement<ProductBoxWithSaleConfigurationProps> {
  const { defaultCloudBluePlan, bundlePlan, upsell, skipBonusScreen } =
    saleConfiguration[0].flatData

  let plan: ProductBoxSlimHookProps["plan"] = defaultCloudBluePlan[0]?.flatData

  const dataTestId =
    index !== null && index !== undefined
      ? `product_box_${index}_select_button`
      : "product_box_select_button"

  if (planInputLabel && saleConfiguration.length > 1) {
    const saleConfigurationWithPlanInputValue = saleConfiguration.filter(
      (configuration) => configuration.flatData.planInputValue
    )

    plan =
      saleConfigurationWithPlanInputValue.length > 1
        ? saleConfigurationWithPlanInputValue.reduce<
            Record<number, CloudBluePlan["flatData"]>
          >((obj, item) => {
            const { planInputValue, defaultCloudBluePlan } = item.flatData

            obj[planInputValue!] = defaultCloudBluePlan[0].flatData

            return obj
          }, {})
        : plan
  }
  const newProduct: ProductBoxSlimHookProps = {
    ...product,
    plan,
    upsell: getUpsellData(upsell),
    planInputLabel,
    saleConfiguration,
    bundlePlan: bundle,
    changePeriod: changePeriodModal?.planPeriod.period,
    isTrialBox,
  }

  if (product.period) {
    newProduct["period"] = product.period
  }

  if (bundle?.length && !isEmpty(bundle[0].flatData.plan)) {
    newProduct["bundle"] = [
      defaultCloudBluePlan[0].flatData,
      bundle[0].flatData.plan[0].flatData,
    ]

    if (!isEmpty(bundlePlan)) {
      newProduct["bundle"] = [bundlePlan[0].flatData]
      newProduct["hasChangedDefaultPlan"] = true
    }

    newProduct["bundleTogglePosition"] = bundle[0].flatData.checkbox
      ? "on"
      : "off"

    if (bundle[0].flatData.title) {
      newProduct["bundledTitle"] = bundle[0].flatData.title
    }
  }

  const productBox = useProductBoxSlim(newProduct)
  const { selectedPlan, button, onChangePeriodModalClick, ...box } = productBox

  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalLoading, setIsModalLoading] = useState(false)
  const isLarge = useBreakpoint("large")
  const isInBasket = useAppSelector((state: AppState) =>
    state.basket.items.some((item) => item.alias === selectedPlan.alias)
  )
  const showChangePeriodModal =
    !isEmpty(changePeriodModal) && isLarge && !isInBasket

  const { addProductToBasket } = useFastCheckoutAction(defaultCloudBluePlan[0])

  const modalButton: ButtonProps = {
    ...button,
    onClick: showChangePeriodModal
      ? () => setIsModalOpen(true)
      : button.onClick,
    dataTestId: dataTestId,
  }

  const basketButton: ButtonProps = {
    ...button,
    text: product.buttonText || button.text,
    onClick: () => addProductToBasket(),
    dataTestId: dataTestId,
  }

  const onChangePeriodModalConfirmed = () => {
    setIsModalLoading(true)

    if (onChangePeriodModalClick) onChangePeriodModalClick()
  }

  const onChangePeriodModalClose = (event: SyntheticEvent) => {
    setIsModalLoading(true)

    if (button.onClick) button.onClick(event)
  }

  return (
    <>
      <PackageBoxSlimMol
        {...box}
        button={skipBonusScreen ? basketButton : modalButton}
        key={`box-${box.id}`}
      />

      {showChangePeriodModal ? (
        <ChangePeriodModal
          isModalOpen={isModalOpen}
          isModalLoading={isModalLoading}
          onConfirmedAction={onChangePeriodModalConfirmed}
          onClose={onChangePeriodModalClose}
          {...changePeriodModal}
        />
      ) : null}
    </>
  )
}
