import { useStaticQuery, graphql } from "gatsby"
import sample from "lodash/sample"

interface Query {
  GraphCmsSquidex: {
    findDomainSearchAiPageSingleton: {
      flatData: {
        examplePrompts: { promptText: string }[]
      }
    }
  }
}

type Hook = {
  getRandomPrompt: { (value: string): string | null }
}

export default function useDomainSerachAiExamplePromptsQuery(): Hook {
  const {
    GraphCmsSquidex: { findDomainSearchAiPageSingleton },
  } = useStaticQuery<Query>(graphql`
    {
      GraphCmsSquidex {
        findDomainSearchAiPageSingleton {
          flatData {
            examplePrompts {
              promptText
            }
          }
        }
      }
    }
  `)

  const examples = findDomainSearchAiPageSingleton.flatData.examplePrompts.map(
    (text) => text.promptText
  )

  return {
    getRandomPrompt: (value: string): string | null => {
      const values = examples.filter((item) => item !== value)

      return sample(values) ?? null
    },
  }
}
