import { forOwn } from "lodash"
import queryString from "query-string"

export const HTTP_METHOD = {
  CONNECT: "CONNECT",
  DELETE: "DELETE",
  GET: "GET",
  HEAD: "HEAD",
  OPTIONS: "OPTIONS",
  POST: "POST",
  PUT: "PUT",
  PATCH: "PATCH",
  TRACE: "TRACE",
}

export const HTTP_STATUS = {
  NONE: 0, // Wewnętrzny kod oznaczajacy niezdefiniowany status
  CONTINUE: 100,
  SWITCHING_PROTOCOLS: 101,
  PROCESSING: 102,
  OK: 200,
  CREATED: 201,
  ACCEPTED: 202,
  NON_AUTHORITATIVE_INFORMATION: 203,
  NO_CONTENT: 204,
  RESET_CONTENT: 205,
  PARTIAL_CONTENT: 206,
  MULTI_STATUS: 207,
  ALREADY_REPORTED: 208,
  IM_USED: 226,
  MULTIPLE_CHOICES: 300,
  MOVED_PERMANENTLY: 301,
  FOUND: 302,
  SEE_OTHER: 303,
  NOT_MODIFIED: 304,
  USE_PROXY: 305,
  TEMPORARY_REDIRECT: 307,
  PERMANENT_REDIRECT: 308,
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  PAYMENT_REQUIRED: 402,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  METHOD_NOT_ALLOWED: 405,
  NOT_ACCEPTABLE: 406,
  PROXY_AUTHENTICATION_REQUIRED: 407,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  GONE: 410,
  LENGTH_REQUIRED: 411,
  PRECONDITION_FAILED: 412,
  PAYLOAD_TOO_LARGE: 413,
  URI_TOO_LONG: 414,
  UNSUPPORTED_MEDIA_TYPE: 415,
  RANGE_NOT_SATISFIABLE: 416,
  EXPECTATION_FAILED: 417,
  MISDIRECTED_REQUEST: 421,
  UNPROCESSABLE_ENTITY: 422,
  LOCKED: 423,
  FAILED_DEPENDENCY: 424,
  UPGRADE_REQUIRED: 426,
  PRECONDITION_REQUIRED: 428,
  TO_MANY_REQUESTS: 429,
  UNASSIGNED: 430,
  REQUEST_HEADER_FIELDS_TOO_LARGE: 431,
  UNAVAILABLE_FOR_LEGAL_REASONS: 451,
  INTERNAL_SERVER_ERROR: 500,
  NOT_IMPLEMENTED: 501,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  HTTP_VERSION_NOT_SUPPORTED: 505,
  VARIANT_ALSO_NEGOTIATES: 506,
  INSUFFICIENT_STORAGE: 507,
  LOOP_DETECTED: 508,
  NOT_EXTENDED: 510,
  NETWORK_AUTHENTICATION_REQUIRED: 511,
}

export function isResponseNone(code: number): boolean {
  return HTTP_STATUS.NONE === code
}

export function isResponseNotFound(code: number): boolean {
  return HTTP_STATUS.NOT_FOUND === code
}

export function isResponseSuccessful(code: number): boolean {
  return HTTP_STATUS.OK <= code && HTTP_STATUS.BAD_REQUEST > code
}

export function isResponseFailed(code: number): boolean {
  return HTTP_STATUS.BAD_REQUEST <= code
}

export function isResponsePending(code: number): boolean {
  return HTTP_STATUS.CONTINUE <= code && HTTP_STATUS.OK > code
}

export function areRequestsFailed(requests: number[]): boolean {
  return (
    requests.filter((status: number) => isResponseFailed(status)).length > 0
  )
}

export function areRequestsPending(requests: number[]): boolean {
  return (
    requests.filter((status: number) => isResponsePending(status)).length > 0
  )
}

export function getRedirectUrlForGetMethod(
  url: string,
  data: Record<string, string> = {}
): string {
  if (url.includes("?")) {
    return url
  }

  return `${url}?${queryString.stringify(data, { arrayFormat: "bracket" })}`
}

function handlePostRedirect(url: string, data: object = {}): void {
  const form = document.createElement("form")
  form.style.display = "none"
  form.method = HTTP_METHOD.POST
  form.action = url

  forOwn(data, (value, key) => {
    const input = document.createElement("input")
    input.type = "hidden"
    input.name = decodeURIComponent(key)
    input.value = decodeURIComponent(value)
    form.appendChild(input)
  })

  const submitInput = document.createElement("input")
  submitInput.type = "submit"
  form.appendChild(submitInput)
  document.body.appendChild(form)
  form.submit()
}

export function doRedirect(
  method: string,
  url: string,
  data: Record<string, string> = {},
  onBeforeRedirect: Function = () => {}
): void {
  onBeforeRedirect()

  if (HTTP_METHOD.POST === method) {
    handlePostRedirect(url, data)

    return
  }

  window.location.href = getRedirectUrlForGetMethod(url, data)
}
